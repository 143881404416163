import React, { useContext, useMemo } from 'react';
import { IDataItem } from '@cpa/base-core/types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { MessageStateContext, ParentMessageContext } from '@cpa/base-core/constants';

import HoverTooltip from '../../../HoverTooltip/HoverTooltip';

import styles from './MessageTitle.module.scss';

interface IMessageTitleProps {
  item: IDataItem;

  // Used only to display inside badge
  srcFieldValue: string;
}

const MessageTitle: React.FC<IMessageTitleProps> = ({ item, srcFieldValue }) => {
  const parentMessageContextValue = useContext(ParentMessageContext);
  const messageStateContextValue = useContext(MessageStateContext);
  const [t] = useTranslation();

  const isRead = useMemo(() => {
    const parentMessageIdentifier = (item?.parentMessage as { identifier: string })?.identifier;
    if (parentMessageIdentifier) {
      if (!item.createdAt || !parentMessageContextValue) return false;
      return parentMessageContextValue >= item.createdAt;
    } else {
      const lastRead = messageStateContextValue ? messageStateContextValue['__lastRead'] : item['__lastRead'];
      const latestMessage = messageStateContextValue ? messageStateContextValue['__latestMessage'] : item['__latestMessage'];
      if (!latestMessage) return true;
      if (!lastRead) return false;
      return lastRead === latestMessage;
    }
  }, [item, messageStateContextValue, parentMessageContextValue]);

  return (
    <div className={styles.wrapper}>
      <HoverTooltip content={t(isRead ? 'messages.read' : 'common.unread')}>
        <div className={classNames(styles.unreadMark, { [styles.hidden]: isRead })}></div>
      </HoverTooltip>
      <span>{srcFieldValue}</span>
    </div>
  );
};

export default MessageTitle;
