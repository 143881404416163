const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/infrastructure/Cluster',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Finfrastructure%2FCluster' }],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': true,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cluster',
    'properties': {
        'azure': {
            'title': 'Azure',
            '$id': 'http://platform.cosmoconsult.com/ontology/infrastructure/Azure',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Finfrastructure%2FAzure' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/azure', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'location': {
                    'title': 'Location',
                    'description': '<div><a href="https://gist.github.com/ausfestivus/04e55c7d80229069bf3bc75870630ec8" target="_blank" rel="noopener">Azure region</a> where infrastructure will be deployed</div>',
                    'type': 'string',
                    'links': [{ 'href': 'http://schema.org/location', 'rel': 'canonical' }],
                },
                'subscriptionId': {
                    'title': 'Subscription Id',
                    'type': 'string',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/subscriptionId', 'rel': 'canonical' }],
                },
                'tenantId': {
                    'title': 'Tenant Id',
                    'type': 'string',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/tenantId', 'rel': 'canonical' }],
                },
                'clientId': {
                    'title': 'Client Id',
                    'type': 'string',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/clientId', 'rel': 'canonical' }],
                },
                'clientSecret': {
                    'title': 'Client Secret',
                    'type': 'string',
                    'format': 'password',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/clientSecret', 'rel': 'canonical' }],
                },
            },
            'required': ['location', 'subscriptionId', 'tenantId', 'clientId', 'clientSecret'],
            'cp_ui': { 'hiddenInTable': true, 'sortOrderForm': 31 },
        },
        'status': {
            'title': 'Status',
            '$id': 'http://platform.cosmoconsult.com/ontology/infrastructure/Status',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Finfrastructure%2FStatus' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/status', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'endDate': {
                    'title': 'End Date',
                    'description': 'The end date of a thing',
                    'type': 'string',
                    'format': 'date-time',
                    'readOnly': true,
                    'cp_ui': { 'sortOrderForm': 13 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/endDate', 'rel': 'canonical' }],
                },
                'startDate': {
                    'title': 'Start Date',
                    'description': 'The start date of a thing',
                    'type': 'string',
                    'format': 'date-time',
                    'readOnly': true,
                    'cp_ui': { 'sortOrderForm': 12 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/startDate', 'rel': 'canonical' }],
                },
                'isRunning': {
                    'title': 'Is Running',
                    'type': 'boolean',
                    'readOnly': true,
                    'cp_ui': { 'sortOrderForm': 7 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/isRunning', 'rel': 'canonical' }],
                },
                'operation': {
                    'title': 'Operation',
                    '$id': 'http://platform.cosmoconsult.com/ontology/infrastructure/Operation',
                    'type': 'string',
                    'links': [
                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Finfrastructure%2FOperation' },
                        { 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/operation', 'rel': 'canonical' },
                    ],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'enum': [
                        'http://platform.cosmoconsult.com/ontology/infrastructure/Up',
                        'http://platform.cosmoconsult.com/ontology/infrastructure/Preview',
                        'http://platform.cosmoconsult.com/ontology/infrastructure/Destroy',
                        'http://platform.cosmoconsult.com/ontology/infrastructure/Refresh',
                    ],
                    'enumNames': ['Up', 'Preview', 'Destroy', 'Refresh'],
                    'readOnly': true,
                    'cp_ui': { 'sortOrderForm': 8 },
                },
                'output': {
                    'title': 'Output',
                    'type': 'string',
                    'readOnly': true,
                    'format': 'cp:multiline',
                    'cp_ui': { 'sortOrderForm': 9 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/output', 'rel': 'canonical' }],
                },
                'exports': {
                    'title': 'Exports',
                    '$id': 'http://platform.cosmoconsult.com/ontology/AnyThing',
                    'type': 'object',
                    'links': [
                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FAnyThing' },
                        { 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/exports', 'rel': 'canonical' },
                    ],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': true,
                    'properties': {
                        'name': {
                            'title': 'Name (Title or Designation)',
                            'description': 'The name of the thing or subject.',
                            'type': 'string',
                            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                        },
                    },
                    'cp_ui': { 'hiddenInForm': true, 'sortOrderForm': 10 },
                },
                'state': {
                    'title': 'State',
                    '$id': 'http://platform.cosmoconsult.com/ontology/AnyThing',
                    'type': 'object',
                    'links': [
                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FAnyThing' },
                        { 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/state', 'rel': 'canonical' },
                    ],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': true,
                    'properties': {
                        'name': {
                            'title': 'Name (Title or Designation)',
                            'description': 'The name of the thing or subject.',
                            'type': 'string',
                            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                        },
                    },
                    'cp_ui': { 'hiddenInForm': true, 'sortOrderForm': 11 },
                },
            },
            'readOnly': true,
            'cp_ui': { 'hiddenInTable': true, 'sortOrderForm': 32 },
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 29 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 30 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'required': ['name', 'azure'],
};
export const ClusterSchema = Schema;
