import * as _ from 'lodash';
import { IComponentWithOptions, IDataItem, ITableRowOption, ITableRowProps } from '@cpa/base-core/types';
import React, { NamedExoticComponent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { AnimationClassNames, CommandBar, DetailsRow, IColumn, ICommandBarItemProps, IDetailsRowBaseProps, ThemeContext } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/hu';
import 'moment/locale/ro';
import 'moment/locale/sv';
import 'moment/locale/zh-cn';

import { itemExpandColumnKey } from '@cpa/base-core/constants/pages';
import { useBoolean } from '@fluentui/react-hooks';
import { useCancellableLoadEntities, useCardWidth, useLiveUpdates, useRelatedLiveUpdates } from '@cpa/base-core/hooks';
import { executeBackendAction } from '@cpa/base-core/helpers';
import { axiosDictionary, executeAggregationTemplate } from '@cpa/base-core/api';
import { useInView } from 'react-intersection-observer';
import { cloneDeepWithMetadata, formatEntries, IODataProps } from '@cp/base-utils';
import { CancelTokenSource, createCancelToken } from '@cpa/base-http';
import LongPress from 'react-long';
import notification from '@cpa/base-core/helpers/toast';
import { i18n } from '@cpa/base-core/app';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { MessageStateContext, ParentMessageContext } from '@cpa/base-core/constants';
import { useMediaQuery } from 'react-responsive';

import { getButtonStyles, getDefaultItemProps, SolutionExplorerRowActionsMode } from '../SolutionExplorer/SolutionExplorer';
import { simplifiedRowRenderer } from '../TableRow/components/SimpleCard/SimpleCard';
import ParentingIndicator from '../TableRow/components/ParentingIndicator/ParentingIndicator';

import styles from './Message.module.scss';
import MessageContainer from './components/MessageContainer/MessageContainer';

const ColorizeFirstRowContext = React.createContext(true);

const Message: React.FC<ITableRowProps & { rowActionsMode?: SolutionExplorerRowActionsMode }> = ({
  page,
  rowId,
  detailedRowProps,
  columns,
  fireInvokeEvent,
  colorizedRows,
  darkMode,
  onContextMenu,
  item,
  parentPropertyJsonPath,
  level,
  loadItems: loadItemsFromProps,
  reloadEmitter,
  order,
  onAddClick,
  onNewChildItemsLoaded,
  pageSize,
  onItemsSelect,
  tableKey,
  selectionEmitter,
}) => {
  const parentMessageContextValue = useContext(ParentMessageContext);
  const defaultIconColor = darkMode ? '#fefefe' : '#383838';
  const user = useSelector((state: IGlobalState) => state.auth.user?.account.email);
  // Simplified view is forced for this row renderer
  const powerUser = false;
  const [t] = useTranslation();
  const theme = useContext(ThemeContext);
  const loadItems = useCancellableLoadEntities(loadItemsFromProps);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const [messageState, setMessageState] = useState<IDataItem | null>(null);

  const colorizeFirstRow = useContext(ColorizeFirstRowContext);
  const paintRow = useMemo(() => {
    return !!colorizedRows && !!detailedRowProps && (colorizeFirstRow ? detailedRowProps.itemIndex % 2 === 0 : detailedRowProps.itemIndex % 2 !== 0);
  }, [colorizedRows, detailedRowProps, colorizeFirstRow]);

  const isMobileDevice = useMediaQuery({ query: '(max-width: 755px)' });

  const cardMargin = useCardWidth();

  const classes = classNames({
    [styles.highlightedRow]: paintRow && !darkMode,
    [styles.highlightedRowDark]: paintRow && darkMode,
    [styles.defaultRow]: !paintRow && !darkMode && !powerUser,
    [styles.defaultRowDark]: !paintRow && darkMode && !powerUser,
    [styles.colors]: !powerUser,
  });

  const [childItems, setChildItems] = useState<IDataItem[]>([]);
  useEffect(() => {
    // We need to update __parentItem on child items if parent is updated.
    // It helps to prevent 'Item modified by another user' errors.
    for (const childItem of childItems) {
      Object.defineProperty(childItem, '__parentItem', {
        enumerable: false,
        configurable: false,
        writable: true,
        value: item,
      });
    }
    // eslint-disable-next-line
  }, [item]);

  const [isFetching, { setTrue: startFetching, setFalse: finishFetching }] = useBoolean(false);

  const childrenCount = item.__originalItem?.__childrenCount || item.__childrenCount || 0;

  const [totalChildItems, setTotalChildItems] = useState<number>(childrenCount);
  const lazyLoadingEnabled = useMemo(
    () => isExpanded && totalChildItems > childItems.length && !isFetching,
    [childItems.length, isExpanded, totalChildItems, isFetching]
  );

  const handleEventReceived = useCallback(
    async (childItem: IDataItem) => {
      const isItemRelated =
        !!parentPropertyJsonPath && (item.__originalItem?.identifier || item.identifier) === _.get(childItem, parentPropertyJsonPath);
      if (!isItemRelated) return;
      const updatedState = await executeAggregationTemplate(page.dataEndpoint?.identifier as string, page.cpTypeUrl as string, 'message-get-state', {
        user: user,
        identifier: item.identifier,
      });
      setMessageState(updatedState[0] || null);
    },
    [item.__originalItem?.identifier, item.identifier, page.cpTypeUrl, page.dataEndpoint?.identifier, parentPropertyJsonPath, user]
  );

  useLiveUpdates(
    loadItems,
    isExpanded ? childItems : [],
    (newItems) => {
      if (!isExpanded) return;
      setChildItems(newItems);
    },
    (newState) => {
      const originalItem = item.__originalItem || item;
      if (typeof newState === 'number') {
        originalItem.__childrenCount = newState;
        setTotalChildItems(newState);
      } else {
        setTotalChildItems((prevTotalItems) => {
          const newTotalItems = newState(prevTotalItems);
          originalItem.__childrenCount = newTotalItems;
          return newTotalItems;
        });
      }
    },
    totalChildItems,
    page,
    (childItem: IDataItem) => {
      return !!parentPropertyJsonPath && (item.__originalItem?.identifier || item.identifier) === _.get(childItem, parentPropertyJsonPath);
    },
    true,
    undefined,
    undefined,
    handleEventReceived
  );

  useRelatedLiveUpdates(
    {},
    loadItems,
    isExpanded ? childItems : [],
    (newItems) => {
      if (!isExpanded) return;
      setChildItems(newItems);
    },
    (newState) => {
      const originalItem = item.__originalItem || item;
      if (typeof newState === 'number') {
        originalItem.__childrenCount = newState;
        setTotalChildItems(newState);
      } else {
        setTotalChildItems((prevTotalItems) => {
          const newTotalItems = newState(prevTotalItems);
          originalItem.__childrenCount = newTotalItems;
          return newTotalItems;
        });
      }
    },
    totalChildItems,
    (childItem: IDataItem) => {
      return !!parentPropertyJsonPath && (item.__originalItem?.identifier || item.identifier) === _.get(childItem, parentPropertyJsonPath);
    },
    true,
    undefined,
    undefined,
    handleEventReceived
  );

  const [lazyLoadingMarker, lazyLoadingMarkerInView] = useInView({
    triggerOnce: false,
    threshold: 0,
  });

  const cancelToken = useRef<CancelTokenSource | null>(null);
  const loadChildItems = useCallback(
    (withAnimation: boolean = true, resetItems: boolean = true, appendLoadedItems: boolean = false, extraOptions: IODataProps = {}) => {
      if (!loadItems || !item.identifier) {
        return;
      }

      if (withAnimation) {
        startFetching();
      }
      if (resetItems) {
        setChildItems([]);
      }

      cancelToken.current?.cancel();
      cancelToken.current = createCancelToken();

      let loadOptions: IODataProps = {};
      if (parentPropertyJsonPath) {
        // Load child solutions
        loadOptions = {
          filter: formatEntries({
            [parentPropertyJsonPath]: item.identifier,
          }) as object,
          orderBy: [['createdAt', 'asc']],
          top: pageSize,
        };
      }

      loadItems(
        {},
        {
          detachedRequest: true,
          disableTriggers: true,
          cancelToken: cancelToken.current || undefined,
          interceptors: {
            beforeRequest: (endpointId, path, queryOptions, ...other) => {
              // Ignore filter from page level. We always fetch all children / components.
              return [
                endpointId,
                path,
                {
                  ...(queryOptions?.$expand ? { $expand: queryOptions.$expand } : {}),
                  ...loadOptions,
                  ...extraOptions,
                },
                ...other,
              ];
            },
          },
        }
      )
        .then(({ items, responses }) => {
          if (responses?.[0]?.totalItems) {
            setTotalChildItems(responses?.[0]?.totalItems);
          }
          setChildItems(
            !appendLoadedItems
              ? items
              : (currentItems): IDataItem[] => {
                  if (!currentItems) {
                    return items;
                  }
                  return [...currentItems, ...items];
                }
          );
          onNewChildItemsLoaded?.(items);
        })
        .finally(() => {
          finishFetching();
        });
    },
    [loadItems, parentPropertyJsonPath, item.identifier, startFetching, order, onNewChildItemsLoaded, finishFetching, pageSize]
  );

  const reloadChildItems = useCallback(() => {
    if (!isExpanded) {
      setChildItems([]);
      return;
    }

    loadChildItems(false, false, false, isExpanded ? { top: pageSize <= childItems.length ? childItems.length + 1 : pageSize } : {});
  }, [isExpanded, loadChildItems, pageSize, childItems.length]);
  const mountedReloadEventHandler = useRef<typeof reloadChildItems>();
  useEffect(() => {
    if (mountedReloadEventHandler.current) {
      reloadEmitter?.off('reload', mountedReloadEventHandler.current);
    }

    // Handle reload
    mountedReloadEventHandler.current = reloadChildItems;
    reloadEmitter?.on('reload', mountedReloadEventHandler.current);

    return (): void => {
      // Unmount
      if (mountedReloadEventHandler.current) {
        reloadEmitter?.off('reload', mountedReloadEventHandler.current);
      }
      reloadEmitter?.off('reload', reloadChildItems);
    };
  }, [reloadEmitter, reloadChildItems]);

  // Lazy loading
  useEffect(() => {
    if (!lazyLoadingMarkerInView || !lazyLoadingEnabled || isFetching || childItems.length === 0 || !isExpanded) {
      return;
    }

    loadChildItems(true, false, true, {
      skip: childItems.length,
    });
  });

  const preventOtherEvents = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const onExpandChildrenIconClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      preventOtherEvents(e);

      if (isExpanded) {
        setIsExpanded(false);
        return;
      }

      setIsExpanded(true);
      loadChildItems();
    },
    [preventOtherEvents, isExpanded, loadChildItems]
  );

  const onAddIconClick = useCallback(
    // @ts-ignore
    (e: React.MouseEvent<HTMLDivElement>) => {
      preventOtherEvents(e);
      // @ts-ignore
      onAddClick?.(undefined, (item: IDataItem) => {
        const itemParent = (item.parentMessage as { identifier: string })?.identifier;
        return {
          name: `Re: ${item.name}`,
          accessPermissions: [
            {
              _type: 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetUser',
              email: item.createdByUser,
            },
          ],
          ...(itemParent ? { parentMessage: { identifier: itemParent }, relatedTo: { identifier: item.identifier } } : {}),
        };
      });
    },
    [preventOtherEvents, onAddClick]
  );

  const onForwardClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      preventOtherEvents(e);
      if (!onAddClick) {
        return;
      }
      onAddClick?.(undefined, (item: IDataItem) => ({
        name: item.name,
        description: item.description,
        parentMessage: undefined,
      }));
    },
    [preventOtherEvents, onAddClick]
  );

  const rowActions = useMemo(() => {
    const activeIconColor = theme?.palette.themePrimary || 'unset';
    const actions: ICommandBarItemProps[] = [];

    if (totalChildItems > 0) {
      actions.push(
        _.merge(
          {
            key: 'expandChildren',
            id: 'children-expand',
            name: t(isExpanded ? 'common.hideChildren' : 'common.expandChildren'),
            onClick: onExpandChildrenIconClick,
            iconProps: {
              className: isExpanded ? '' : AnimationClassNames.rotateN90deg,
            },
          },
          getDefaultItemProps('ChevronDown', isExpanded ? activeIconColor : defaultIconColor)
        )
      );
    }

    // if (onAddClick && item?.createdByUser !== user) {
    //   actions.push({
    //     key: 'reply',
    //     id: 'reply',
    //     name: t('messages.reply'),
    //     onClick: onAddIconClick,
    //     ...getDefaultItemProps('reply', defaultIconColor),
    //   });
    // }
    // if (onAddClick) {
    //   actions.push({
    //     key: 'forward',
    //     id: 'forward',
    //     name: t('messages.forward'),
    //     onClick: onForwardClick,
    //     ...getDefaultItemProps('forward', defaultIconColor),
    //   });
    // }
    return actions;
  }, [theme?.palette.themePrimary, totalChildItems, t, defaultIconColor, isExpanded, onExpandChildrenIconClick]);

  const onExpandColumnRender = useCallback(() => {
    const overflowButtonStyles = getButtonStyles(defaultIconColor);
    return (
      <div className={styles.expandButtonWrapper} onDoubleClick={preventOtherEvents} onMouseDown={preventOtherEvents}>
        <CommandBar
          // Key is used here as a workaround.
          // FluentUI doesn't pass updated row actions to underlying OverflowSet for some reason.
          key={rowActions.length}
          styles={{
            root: {
              padding: 0,
              backgroundColor: 'transparent',
              height: 'initial',
            },
          }}
          overflowButtonProps={{
            styles: {
              ...overflowButtonStyles,
              root: {
                ...((overflowButtonStyles.root as object) || {}),
                padding: '7px 2px',
              },
            },
          }}
          items={rowActions}
        />
      </div>
    );
  }, [preventOtherEvents, defaultIconColor, rowActions]);

  const columnsToRender = useMemo(() => {
    if (!parentPropertyJsonPath || !loadItems) {
      return columns;
    }

    let firstColumn: IColumn;

    return columns.map((column) => {
      if (column.key === itemExpandColumnKey) {
        return {
          ...column,
          onRender: onExpandColumnRender,
        };
      }

      if (!firstColumn && column.key && !column.key.startsWith('__file-')) {
        firstColumn = { ...column };

        const oldOnRender = firstColumn.onRender;
        firstColumn.onRender = (...args): JSX.Element | null => {
          if (!firstColumn.fieldName) {
            return null;
          }

          const child = oldOnRender ? oldOnRender?.(...args) : args[0][firstColumn.fieldName] || null;
          return (
            <div
              style={{
                paddingLeft: level * 15,
              }}
            >
              {child}
            </div>
          );
        };

        return firstColumn;
      }

      return column;
    });
  }, [parentPropertyJsonPath, columns, onExpandColumnRender, level, loadItems]);

  useEffect(() => {
    setIsExpanded(false);
    setChildItems([]);
    setTotalChildItems(childrenCount);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowId, item?.identifier, order]);

  const onLongPress = useCallback((): void => {
    // For some reason LongPress library did not provide any event for handler
    // to make possible to open context menu it is passed as empty function
    // TODO: Fix
  }, []);

  const colorsStyle = useMemo(() => {
    const originalItem: IDataItem = item.__originalItem || item;
    const colors = (originalItem?.['__workloadInfo'] as IDataItem<unknown> | null)?.colors as string[];
    if (colors?.length) {
      if (colors.length === 1) {
        return colors[0];
      }
      return `linear-gradient(to bottom, ${colors.join(', ')})`;
    }
    return null;
  }, [item]);

  const childRenderer = useCallback(
    (columns: IColumn[], itemId: string, childItems: IDataItem[], isFetching: boolean, totalItems: number): JSX.Element => {
      return (
        <div style={{ display: 'flex', justifyContent: isMobileDevice ? undefined : 'flex-end' }}>
          <MessageContainer
            columns={columns}
            parentMessage={item}
            childItems={childItems}
            isFetching={isFetching}
            totalItems={totalItems}
            lastRead={item?.__originalItem?.['__lastRead'] as string}
            pageIdentifier={page.identifier!}
            selection={detailedRowProps.selection}
            onItemsSelect={onItemsSelect}
            tableKey={`${tableKey}-${itemId}`}
            selectionEmitter={selectionEmitter}
          />
        </div>
      );
    },
    [detailedRowProps.selection, isMobileDevice, item, onItemsSelect, page.identifier, selectionEmitter, tableKey]
  );

  return (
    <MessageStateContext.Provider value={messageState || undefined}>
      <ParentMessageContext.Provider value={parentMessageContextValue || (item?.__originalItem?.['__lastReadTimestamp'] as string | undefined)}>
        <div
          className={classNames({
            [styles.rowWrapper]: true,
            [styles.expandableRow]: !!parentPropertyJsonPath,
            [styles.goldGradient]: !powerUser,
            [styles.goldGradientHighlightedLight]: !powerUser && paintRow && !darkMode,
            [styles.goldGradientHighlightedDark]: !powerUser && paintRow && darkMode,
            [styles.goldGradientVisible]: !powerUser && detailedRowProps.selection.isIndexSelected(detailedRowProps.itemIndex),
          })}
          style={{ margin: powerUser ? undefined : '10px 0', marginLeft: powerUser ? undefined : `${level !== 0 ? cardMargin : 0}px` }}
        >
          <div>
            <LongPress time={1000} onLongPress={onLongPress}>
              <div data-selection-invoke={fireInvokeEvent} id={rowId} onDoubleClick={onExpandChildrenIconClick} onContextMenu={onContextMenu}>
                <DetailsRow
                  {...(detailedRowProps as IDetailsRowBaseProps)}
                  columns={columnsToRender}
                  styles={{
                    root: {
                      borderRadius: powerUser ? undefined : '12px',
                      '&::after': {
                        background: powerUser ? undefined : colorsStyle,
                      },
                    },
                  }}
                  className={classes}
                  onRenderField={powerUser ? undefined : (props, defaultRender) => simplifiedRowRenderer(props, defaultRender, level, page)}
                />
              </div>
            </LongPress>
          </div>
          {isExpanded && (childItems.length > 0 || isFetching) && <ParentingIndicator level={level} />}
          <div onClick={preventOtherEvents} onDoubleClick={preventOtherEvents} data-selection-disabled={true}>
            {isExpanded && (childItems.length > 0 || isFetching) && (
              <ColorizeFirstRowContext.Provider value={!paintRow}>
                <div style={{ marginBottom: 5 }}>
                  {childRenderer(columns, rowId || 'item', childItems, isFetching, isExpanded ? totalChildItems : 0)}
                </div>
                {lazyLoadingEnabled && <div ref={lazyLoadingMarker} />}
              </ColorizeFirstRowContext.Provider>
            )}
          </div>
        </div>
      </ParentMessageContext.Provider>
    </MessageStateContext.Provider>
  );
};

const MessageMemoized: NamedExoticComponent & IComponentWithOptions<ITableRowOption> = React.memo(Message, _.isEqual);
MessageMemoized.options = {
  table: {
    disableVirtualization: true,
    customActions: [
      {
        key: 'delete',
        text: 'common.delete',
        icon: 'Delete',
        canShow(selectedItems, page, schema, user): boolean {
          return selectedItems.length !== 0 && selectedItems[0].createdByUser !== user && !selectedItems[0]['parentMessage'];
        },
        async onClick(selectedItems, { page, clearTableSelection }) {
          await executeBackendAction(
            axiosDictionary.appDataService,
            page.identifier,
            'set-deleted',
            selectedItems.map((item) => item.identifier as string)
          );
          clearTableSelection();
        },
      },
      {
        key: 'delete',
        text: 'common.revoke',
        icon: 'Delete',
        canShow(selectedItems, page, schema, user): boolean {
          return selectedItems.length !== 0 && selectedItems[0].createdByUser === user;
        },
        async onClick(selectedItems, { clearTableSelection, onDelete, reloadCurrentItems }) {
          const toastId = notification.info(i18n.t('common.deletingItem'));
          try {
            await onDelete(selectedItems);
            notification.dismiss(toastId);
            notification.success(i18n.t('common.itemDeleted'));
            clearTableSelection();
            await reloadCurrentItems();
          } catch (error) {
            if (toastId) {
              notification.dismiss(toastId);
            }
            notification.error(error.message);
            console.error(error);
          }
        },
      },
      {
        key: 'reply',
        text: 'messages.reply',
        icon: 'reply',
        canShow(selectedItems): boolean {
          return selectedItems.length === 1;
        },
        async onClick(selectedItems, { clearTableSelection, onAddClick, schema }) {
          const item = selectedItems[0];
          const itemParent = (item.parentMessage as { identifier: string })?.identifier;
          const reducedReplySchema = cloneDeepWithMetadata(schema);
          const schemaToUse = {
            ...reducedReplySchema,
            properties: {
              ...reducedReplySchema.properties,
              accessPermissions: {
                ...reducedReplySchema.properties.accessPermissions,
                cp_ui: { ...reducedReplySchema.properties.accessPermissions.cp_ui, hiddenInForm: true },
              },
              name: { ...reducedReplySchema.properties.name, cp_ui: { ...reducedReplySchema.properties.name.cp_ui, hiddenInForm: true } },
            },
          };

          onAddClick(!itemParent ? item : undefined, undefined, schemaToUse, {
            name: `Re: ${item.name}`,
            accessPermissions: item.accessPermissions,
            ...(itemParent ? { parentMessage: { identifier: itemParent }, relatedTo: { identifier: item.identifier } } : {}),
          });
          clearTableSelection();
        },
      },
      {
        key: 'forward',
        text: 'messages.forward',
        icon: 'forward',
        canShow(selectedItems): boolean {
          return selectedItems[0] && !!selectedItems[0].parentMessage && selectedItems.length === 1;
        },
        async onClick(selectedItems, { clearTableSelection, onAddClick, schema }) {
          const item = selectedItems[0];
          const reducedForwardSchema = cloneDeepWithMetadata(schema);
          const schemaToUse = {
            ...reducedForwardSchema,
            properties: {
              ...reducedForwardSchema.properties,
              name: { ...reducedForwardSchema.properties.name, cp_ui: { ...reducedForwardSchema.properties.name.cp_ui, hiddenInForm: true } },
              description: {
                ...reducedForwardSchema.properties.description,
                cp_ui: { ...reducedForwardSchema.properties.description.cp_ui, hiddenInForm: true },
              },
            },
          };
          onAddClick(undefined, undefined, schemaToUse, {
            name: item.name,
            description: item.description,
          });
          clearTableSelection();
        },
      },
      {
        key: 'modify',
        text: 'common.edit',
        icon: 'Edit',
        canShow(selectedItems, user): boolean {
          return selectedItems.length === 1 && selectedItems[0].createdByUser === user;
        },
        replaceDefaultAction: true,
        onClick(selectedItems, { modifyActionHandler }) {
          modifyActionHandler(selectedItems);
        },
      },
      {
        key: 'view',
        text: 'messages.markAsRead',
        icon: 'ViewOriginal',
        replaceDefaultAction: true,
        canShow(): boolean {
          return false;
        },
        async onClick() {
          return null;
        },
      },
    ],
  },
};
export default MessageMemoized;
