const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/Cpa',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpa' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FAttachment?$filter=about%2F_type%20eq%20'{type}'%20and%20about%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FComment?$filter=about%2F_type%20eq%20'{type}'%20and%20about%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FRating?$filter=about%2F_type%20eq%20'{type}'%20and%20about%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FTag?$filter=about%2F_type%20eq%20'{type}'%20and%20about%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FVoting?$filter=about%2F_type%20eq%20'{type}'%20and%20about%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaFooter?$filter=cpas%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpHtmlTemplate?$filter=sharedWithCpas%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpRelationRequest?$filter=cpa%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpFeedback?$filter=cpa%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaPage?$filter=cpas%2Fidentifier%20eq%20'{identifier}'",
        },
    ],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': true,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cpa',
    'properties': {
        'configurations': {
            'title': 'Configurations',
            'description': '<div>Different configurations for different environments/stages (e.g. dev, staging, production).</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpaConfigurationEnvironments',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaConfigurationEnvironments' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/configurations', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'developmentConfiguration': {
                    'title': 'Development Configuration',
                    '$id': 'http://platform.cosmoconsult.com/ontology/CpaConfiguration',
                    'type': 'object',
                    'links': [
                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaConfiguration' },
                        { 'href': 'http://platform.cosmoconsult.com/ontology/developmentConfiguration', 'rel': 'canonical' },
                    ],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'properties': {
                        'apiKey': {
                            'title': 'Api Key',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 52 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/apiKey', 'rel': 'canonical' }],
                        },
                        'assetFiles': {
                            'title': 'Asset Files',
                            'type': 'array',
                            'items': {
                                '$id': 'http://platform.cosmoconsult.com/ontology/KeyValue',
                                'type': 'object',
                                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FKeyValue' }],
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': false,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'title': 'Key Value',
                                'properties': {
                                    'value': {
                                        'title': 'Value',
                                        'description': "The value of the quantitative value or property value node.\\n\\n* For [[QuantitativeValue]] and [[MonetaryAmount]], the recommended type for values is 'Number'.\\n* For [[PropertyValue]], it can be 'Text;', 'Number', 'Boolean', or 'StructuredValue'.\\n* Use values from 0123456789 (Unicode 'DIGIT ZERO' (U+0030) to 'DIGIT NINE' (U+0039)) rather than superficially similiar Unicode symbols.\\n* Use '.' (Unicode 'FULL STOP' (U+002E)) rather than ',' to indicate a decimal point. Avoid using these symbols as a readability separator.",
                                        'type': 'string',
                                        'format': 'data-url',
                                        'links': [{ 'href': 'http://schema.org/value', 'rel': 'canonical' }],
                                    },
                                    'key': {
                                        'title': 'Key',
                                        'type': 'string',
                                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/key', 'rel': 'canonical' }],
                                    },
                                },
                            },
                            'cp_ui': { 'sortOrderForm': 53 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/assetFiles', 'rel': 'canonical' }],
                        },
                        'baseContainerImage': {
                            'title': 'Base Container Image',
                            '$id': 'http://platform.cosmoconsult.com/ontology/ContainerImage',
                            'type': 'object',
                            'links': [
                                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FContainerImage' },
                                { 'href': 'http://platform.cosmoconsult.com/ontology/baseContainerImage', 'rel': 'canonical' },
                            ],
                            'cp_handledByApiGateway': false,
                            'cp_disableTrash': false,
                            'cp_disableLocalization': false,
                            'cp_disableVersions': false,
                            'cp_disableClone': false,
                            'cp_disableLazyLoading': false,
                            'patternProperties': { '^[_@].*$': {} },
                            'additionalProperties': false,
                            'properties': {
                                'version': {
                                    'title': 'Version',
                                    'description': '<div>The version of the container image.</div>',
                                    'type': 'string',
                                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/version', 'rel': 'canonical' }],
                                },
                                'identifier': {
                                    'title': 'Identifier',
                                    'description': '<div>The name of the container image.</div>',
                                    'type': 'string',
                                    'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                                },
                            },
                            'required': ['identifier'],
                            'cp_ui': { 'sortOrderForm': 54 },
                        },
                        'copyright': {
                            'title': 'Copyright',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 55 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/copyright', 'rel': 'canonical' }],
                        },
                        'environmentVariables': {
                            'title': 'Environment Variables',
                            'type': 'array',
                            'items': {
                                '$id': 'http://platform.cosmoconsult.com/ontology/EnvironmentVariable',
                                'type': 'object',
                                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FEnvironmentVariable' }],
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': false,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'title': 'Environment Variable',
                                'properties': {
                                    'value': {
                                        'title': 'Value',
                                        'description': "The value of the quantitative value or property value node.\\n\\n* For [[QuantitativeValue]] and [[MonetaryAmount]], the recommended type for values is 'Number'.\\n* For [[PropertyValue]], it can be 'Text;', 'Number', 'Boolean', or 'StructuredValue'.\\n* Use values from 0123456789 (Unicode 'DIGIT ZERO' (U+0030) to 'DIGIT NINE' (U+0039)) rather than superficially similiar Unicode symbols.\\n* Use '.' (Unicode 'FULL STOP' (U+002E)) rather than ',' to indicate a decimal point. Avoid using these symbols as a readability separator.",
                                        'type': 'string',
                                        'links': [{ 'href': 'http://schema.org/value', 'rel': 'canonical' }],
                                    },
                                    'key': {
                                        'title': 'Key',
                                        'type': 'string',
                                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/key', 'rel': 'canonical' }],
                                    },
                                },
                                'required': ['value', 'key'],
                            },
                            'cp_ui': { 'sortOrderForm': 56 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/environmentVariables', 'rel': 'canonical' }],
                        },
                        'googleAnalyticsTrackingId': {
                            'title': 'Google Analytics Tracking Id',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 57 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/googleAnalyticsTrackingId', 'rel': 'canonical' }],
                        },
                        'initScript': {
                            'title': 'Init Script',
                            'type': 'string',
                            'format': 'cp:code',
                            'cp_ui': { 'sortOrderForm': 58 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/initScript', 'rel': 'canonical' }],
                        },
                        'msalAuthAuthority': {
                            'title': 'Msal Auth Authority',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 51 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthAuthority', 'rel': 'canonical' }],
                        },
                        'msalAuthAuthorityMetadata': {
                            'title': 'Msal Auth Authority Metadata',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 50 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthAuthorityMetadata', 'rel': 'canonical' }],
                        },
                        'msalAuthClientId': {
                            'title': 'Msal Auth Client Id',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 48 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthClientId', 'rel': 'canonical' }],
                        },
                        'msalAuthJourneyEditProfile': {
                            'title': 'Msal Auth Journey Edit Profile',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 59 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthJourneyEditProfile', 'rel': 'canonical' }],
                        },
                        'msalAuthJourneyLinkAccount': {
                            'title': 'Msal Auth Journey Link Account',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 60 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthJourneyLinkAccount', 'rel': 'canonical' }],
                        },
                        'msalAuthJourneyReset': {
                            'title': 'Msal Auth Journey Reset',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 61 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthJourneyReset', 'rel': 'canonical' }],
                        },
                        'msalAuthJourneySignIn': {
                            'title': 'Msal Auth Journey Sign In',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 62 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthJourneySignIn', 'rel': 'canonical' }],
                        },
                        'msalAuthScopes': {
                            'title': 'Msal Auth Scopes',
                            'type': 'array',
                            'items': { 'type': 'string' },
                            'cp_ui': { 'sortOrderForm': 49 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthScopes', 'rel': 'canonical' }],
                        },
                        'msalIgnoreCodes': {
                            'title': 'Msal Ignore Codes',
                            'type': 'array',
                            'items': { 'type': 'string' },
                            'cp_ui': { 'sortOrderForm': 63 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalIgnoreCodes', 'rel': 'canonical' }],
                        },
                        'msalReloadCodes': {
                            'title': 'Msal Reload Codes',
                            'type': 'array',
                            'items': { 'type': 'string' },
                            'cp_ui': { 'sortOrderForm': 64 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalReloadCodes', 'rel': 'canonical' }],
                        },
                        'searchServices': {
                            'title': 'Search Services',
                            'type': 'array',
                            'items': {
                                '$id': 'http://platform.cosmoconsult.com/ontology/SearchService',
                                'type': 'object',
                                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSearchService' }],
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': false,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'title': 'Search Service',
                                'properties': {
                                    'searchIndexes': {
                                        'title': 'Search Indexes',
                                        'type': 'array',
                                        'items': { 'type': 'string' },
                                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/searchIndexes', 'rel': 'canonical' }],
                                    },
                                    'url': {
                                        'title': 'Url',
                                        'description': '<div>Absolute URL of the item, if there is a URL that can be used 1:1 for this item (as a reference).</div>',
                                        'type': 'string',
                                        'format': 'uri',
                                        'links': [{ 'href': 'http://schema.org/url', 'rel': 'canonical' }],
                                    },
                                },
                            },
                            'cp_ui': { 'sortOrderForm': 65 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/searchServices', 'rel': 'canonical' }],
                        },
                        'supportedCpCultures': {
                            'title': 'Supported Cp Cultures',
                            'type': 'array',
                            'items': {
                                '$id': 'http://platform.cosmoconsult.com/ontology/CpCulture',
                                'type': 'object',
                                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpCulture' }],
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': false,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'title': 'Cp Culture',
                                'description': 'A culture based on RFC 4646',
                                'properties': { 'identifier': { 'type': 'string', 'title': 'Cp Culture' } },
                            },
                            'cp_ui': { 'sortOrderForm': 66 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/supportedCpCultures', 'rel': 'canonical' }],
                        },
                        'disableDarkMode': {
                            'title': 'Disable Dark Mode',
                            'type': 'boolean',
                            'cp_ui': { 'sortOrderForm': 67 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/disableDarkMode', 'rel': 'canonical' }],
                        },
                        'disableSearchEngineIndexing': {
                            'title': 'Disable Search Engine Indexing',
                            'type': 'boolean',
                            'cp_ui': { 'sortOrderForm': 68 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/disableSearchEngineIndexing', 'rel': 'canonical' }],
                        },
                        'name': {
                            'title': 'Name (Title or Designation)',
                            'description': 'The name of the thing or subject.',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 69 },
                            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                        },
                        'url': {
                            'title': 'Url',
                            'description': '<div>Absolute URL of the item, if there is a URL that can be used 1:1 for this item (as a reference).</div>',
                            'type': 'string',
                            'format': 'uri',
                            'cp_ui': { 'sortOrderForm': 70 },
                            'links': [{ 'href': 'http://schema.org/url', 'rel': 'canonical' }],
                        },
                    },
                    'required': [
                        'apiKey',
                        'copyright',
                        'msalAuthAuthority',
                        'msalAuthClientId',
                        'msalAuthJourneyEditProfile',
                        'msalAuthJourneyLinkAccount',
                        'msalAuthJourneyReset',
                        'msalAuthJourneySignIn',
                        'name',
                        'baseContainerImage',
                    ],
                    'cp_ui': { 'sortOrderForm': 31 },
                },
                'localConfiguration': {
                    'title': 'Local Configuration',
                    '$id': 'http://platform.cosmoconsult.com/ontology/CpaConfiguration',
                    'type': 'object',
                    'links': [
                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaConfiguration' },
                        { 'href': 'http://platform.cosmoconsult.com/ontology/localConfiguration', 'rel': 'canonical' },
                    ],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'properties': {
                        'apiKey': {
                            'title': 'Api Key',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 52 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/apiKey', 'rel': 'canonical' }],
                        },
                        'assetFiles': {
                            'title': 'Asset Files',
                            'type': 'array',
                            'items': {
                                '$id': 'http://platform.cosmoconsult.com/ontology/KeyValue',
                                'type': 'object',
                                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FKeyValue' }],
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': false,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'title': 'Key Value',
                                'properties': {
                                    'value': {
                                        'title': 'Value',
                                        'description': "The value of the quantitative value or property value node.\\n\\n* For [[QuantitativeValue]] and [[MonetaryAmount]], the recommended type for values is 'Number'.\\n* For [[PropertyValue]], it can be 'Text;', 'Number', 'Boolean', or 'StructuredValue'.\\n* Use values from 0123456789 (Unicode 'DIGIT ZERO' (U+0030) to 'DIGIT NINE' (U+0039)) rather than superficially similiar Unicode symbols.\\n* Use '.' (Unicode 'FULL STOP' (U+002E)) rather than ',' to indicate a decimal point. Avoid using these symbols as a readability separator.",
                                        'type': 'string',
                                        'format': 'data-url',
                                        'links': [{ 'href': 'http://schema.org/value', 'rel': 'canonical' }],
                                    },
                                    'key': {
                                        'title': 'Key',
                                        'type': 'string',
                                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/key', 'rel': 'canonical' }],
                                    },
                                },
                            },
                            'cp_ui': { 'sortOrderForm': 53 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/assetFiles', 'rel': 'canonical' }],
                        },
                        'baseContainerImage': {
                            'title': 'Base Container Image',
                            '$id': 'http://platform.cosmoconsult.com/ontology/ContainerImage',
                            'type': 'object',
                            'links': [
                                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FContainerImage' },
                                { 'href': 'http://platform.cosmoconsult.com/ontology/baseContainerImage', 'rel': 'canonical' },
                            ],
                            'cp_handledByApiGateway': false,
                            'cp_disableTrash': false,
                            'cp_disableLocalization': false,
                            'cp_disableVersions': false,
                            'cp_disableClone': false,
                            'cp_disableLazyLoading': false,
                            'patternProperties': { '^[_@].*$': {} },
                            'additionalProperties': false,
                            'properties': {
                                'version': {
                                    'title': 'Version',
                                    'description': '<div>The version of the container image.</div>',
                                    'type': 'string',
                                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/version', 'rel': 'canonical' }],
                                },
                                'identifier': {
                                    'title': 'Identifier',
                                    'description': '<div>The name of the container image.</div>',
                                    'type': 'string',
                                    'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                                },
                            },
                            'required': ['identifier'],
                            'cp_ui': { 'sortOrderForm': 54 },
                        },
                        'copyright': {
                            'title': 'Copyright',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 55 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/copyright', 'rel': 'canonical' }],
                        },
                        'environmentVariables': {
                            'title': 'Environment Variables',
                            'type': 'array',
                            'items': {
                                '$id': 'http://platform.cosmoconsult.com/ontology/EnvironmentVariable',
                                'type': 'object',
                                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FEnvironmentVariable' }],
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': false,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'title': 'Environment Variable',
                                'properties': {
                                    'value': {
                                        'title': 'Value',
                                        'description': "The value of the quantitative value or property value node.\\n\\n* For [[QuantitativeValue]] and [[MonetaryAmount]], the recommended type for values is 'Number'.\\n* For [[PropertyValue]], it can be 'Text;', 'Number', 'Boolean', or 'StructuredValue'.\\n* Use values from 0123456789 (Unicode 'DIGIT ZERO' (U+0030) to 'DIGIT NINE' (U+0039)) rather than superficially similiar Unicode symbols.\\n* Use '.' (Unicode 'FULL STOP' (U+002E)) rather than ',' to indicate a decimal point. Avoid using these symbols as a readability separator.",
                                        'type': 'string',
                                        'links': [{ 'href': 'http://schema.org/value', 'rel': 'canonical' }],
                                    },
                                    'key': {
                                        'title': 'Key',
                                        'type': 'string',
                                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/key', 'rel': 'canonical' }],
                                    },
                                },
                                'required': ['value', 'key'],
                            },
                            'cp_ui': { 'sortOrderForm': 56 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/environmentVariables', 'rel': 'canonical' }],
                        },
                        'googleAnalyticsTrackingId': {
                            'title': 'Google Analytics Tracking Id',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 57 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/googleAnalyticsTrackingId', 'rel': 'canonical' }],
                        },
                        'initScript': {
                            'title': 'Init Script',
                            'type': 'string',
                            'format': 'cp:code',
                            'cp_ui': { 'sortOrderForm': 58 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/initScript', 'rel': 'canonical' }],
                        },
                        'msalAuthAuthority': {
                            'title': 'Msal Auth Authority',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 51 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthAuthority', 'rel': 'canonical' }],
                        },
                        'msalAuthAuthorityMetadata': {
                            'title': 'Msal Auth Authority Metadata',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 50 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthAuthorityMetadata', 'rel': 'canonical' }],
                        },
                        'msalAuthClientId': {
                            'title': 'Msal Auth Client Id',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 48 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthClientId', 'rel': 'canonical' }],
                        },
                        'msalAuthJourneyEditProfile': {
                            'title': 'Msal Auth Journey Edit Profile',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 59 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthJourneyEditProfile', 'rel': 'canonical' }],
                        },
                        'msalAuthJourneyLinkAccount': {
                            'title': 'Msal Auth Journey Link Account',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 60 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthJourneyLinkAccount', 'rel': 'canonical' }],
                        },
                        'msalAuthJourneyReset': {
                            'title': 'Msal Auth Journey Reset',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 61 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthJourneyReset', 'rel': 'canonical' }],
                        },
                        'msalAuthJourneySignIn': {
                            'title': 'Msal Auth Journey Sign In',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 62 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthJourneySignIn', 'rel': 'canonical' }],
                        },
                        'msalAuthScopes': {
                            'title': 'Msal Auth Scopes',
                            'type': 'array',
                            'items': { 'type': 'string' },
                            'cp_ui': { 'sortOrderForm': 49 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthScopes', 'rel': 'canonical' }],
                        },
                        'msalIgnoreCodes': {
                            'title': 'Msal Ignore Codes',
                            'type': 'array',
                            'items': { 'type': 'string' },
                            'cp_ui': { 'sortOrderForm': 63 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalIgnoreCodes', 'rel': 'canonical' }],
                        },
                        'msalReloadCodes': {
                            'title': 'Msal Reload Codes',
                            'type': 'array',
                            'items': { 'type': 'string' },
                            'cp_ui': { 'sortOrderForm': 64 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalReloadCodes', 'rel': 'canonical' }],
                        },
                        'searchServices': {
                            'title': 'Search Services',
                            'type': 'array',
                            'items': {
                                '$id': 'http://platform.cosmoconsult.com/ontology/SearchService',
                                'type': 'object',
                                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSearchService' }],
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': false,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'title': 'Search Service',
                                'properties': {
                                    'searchIndexes': {
                                        'title': 'Search Indexes',
                                        'type': 'array',
                                        'items': { 'type': 'string' },
                                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/searchIndexes', 'rel': 'canonical' }],
                                    },
                                    'url': {
                                        'title': 'Url',
                                        'description': '<div>Absolute URL of the item, if there is a URL that can be used 1:1 for this item (as a reference).</div>',
                                        'type': 'string',
                                        'format': 'uri',
                                        'links': [{ 'href': 'http://schema.org/url', 'rel': 'canonical' }],
                                    },
                                },
                            },
                            'cp_ui': { 'sortOrderForm': 65 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/searchServices', 'rel': 'canonical' }],
                        },
                        'supportedCpCultures': {
                            'title': 'Supported Cp Cultures',
                            'type': 'array',
                            'items': {
                                '$id': 'http://platform.cosmoconsult.com/ontology/CpCulture',
                                'type': 'object',
                                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpCulture' }],
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': false,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'title': 'Cp Culture',
                                'description': 'A culture based on RFC 4646',
                                'properties': { 'identifier': { 'type': 'string', 'title': 'Cp Culture' } },
                            },
                            'cp_ui': { 'sortOrderForm': 66 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/supportedCpCultures', 'rel': 'canonical' }],
                        },
                        'disableDarkMode': {
                            'title': 'Disable Dark Mode',
                            'type': 'boolean',
                            'cp_ui': { 'sortOrderForm': 67 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/disableDarkMode', 'rel': 'canonical' }],
                        },
                        'disableSearchEngineIndexing': {
                            'title': 'Disable Search Engine Indexing',
                            'type': 'boolean',
                            'cp_ui': { 'sortOrderForm': 68 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/disableSearchEngineIndexing', 'rel': 'canonical' }],
                        },
                        'name': {
                            'title': 'Name (Title or Designation)',
                            'description': 'The name of the thing or subject.',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 69 },
                            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                        },
                        'url': {
                            'title': 'Url',
                            'description': '<div>Absolute URL of the item, if there is a URL that can be used 1:1 for this item (as a reference).</div>',
                            'type': 'string',
                            'format': 'uri',
                            'cp_ui': { 'sortOrderForm': 70 },
                            'links': [{ 'href': 'http://schema.org/url', 'rel': 'canonical' }],
                        },
                    },
                    'required': [
                        'apiKey',
                        'copyright',
                        'msalAuthAuthority',
                        'msalAuthClientId',
                        'msalAuthJourneyEditProfile',
                        'msalAuthJourneyLinkAccount',
                        'msalAuthJourneyReset',
                        'msalAuthJourneySignIn',
                        'name',
                        'baseContainerImage',
                    ],
                    'cp_ui': { 'sortOrderForm': 30 },
                },
                'productionConfiguration': {
                    'title': 'Production Configuration',
                    '$id': 'http://platform.cosmoconsult.com/ontology/CpaConfiguration',
                    'type': 'object',
                    'links': [
                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaConfiguration' },
                        { 'href': 'http://platform.cosmoconsult.com/ontology/productionConfiguration', 'rel': 'canonical' },
                    ],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'properties': {
                        'apiKey': {
                            'title': 'Api Key',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 52 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/apiKey', 'rel': 'canonical' }],
                        },
                        'assetFiles': {
                            'title': 'Asset Files',
                            'type': 'array',
                            'items': {
                                '$id': 'http://platform.cosmoconsult.com/ontology/KeyValue',
                                'type': 'object',
                                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FKeyValue' }],
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': false,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'title': 'Key Value',
                                'properties': {
                                    'value': {
                                        'title': 'Value',
                                        'description': "The value of the quantitative value or property value node.\\n\\n* For [[QuantitativeValue]] and [[MonetaryAmount]], the recommended type for values is 'Number'.\\n* For [[PropertyValue]], it can be 'Text;', 'Number', 'Boolean', or 'StructuredValue'.\\n* Use values from 0123456789 (Unicode 'DIGIT ZERO' (U+0030) to 'DIGIT NINE' (U+0039)) rather than superficially similiar Unicode symbols.\\n* Use '.' (Unicode 'FULL STOP' (U+002E)) rather than ',' to indicate a decimal point. Avoid using these symbols as a readability separator.",
                                        'type': 'string',
                                        'format': 'data-url',
                                        'links': [{ 'href': 'http://schema.org/value', 'rel': 'canonical' }],
                                    },
                                    'key': {
                                        'title': 'Key',
                                        'type': 'string',
                                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/key', 'rel': 'canonical' }],
                                    },
                                },
                            },
                            'cp_ui': { 'sortOrderForm': 53 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/assetFiles', 'rel': 'canonical' }],
                        },
                        'baseContainerImage': {
                            'title': 'Base Container Image',
                            '$id': 'http://platform.cosmoconsult.com/ontology/ContainerImage',
                            'type': 'object',
                            'links': [
                                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FContainerImage' },
                                { 'href': 'http://platform.cosmoconsult.com/ontology/baseContainerImage', 'rel': 'canonical' },
                            ],
                            'cp_handledByApiGateway': false,
                            'cp_disableTrash': false,
                            'cp_disableLocalization': false,
                            'cp_disableVersions': false,
                            'cp_disableClone': false,
                            'cp_disableLazyLoading': false,
                            'patternProperties': { '^[_@].*$': {} },
                            'additionalProperties': false,
                            'properties': {
                                'version': {
                                    'title': 'Version',
                                    'description': '<div>The version of the container image.</div>',
                                    'type': 'string',
                                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/version', 'rel': 'canonical' }],
                                },
                                'identifier': {
                                    'title': 'Identifier',
                                    'description': '<div>The name of the container image.</div>',
                                    'type': 'string',
                                    'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                                },
                            },
                            'required': ['identifier'],
                            'cp_ui': { 'sortOrderForm': 54 },
                        },
                        'copyright': {
                            'title': 'Copyright',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 55 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/copyright', 'rel': 'canonical' }],
                        },
                        'environmentVariables': {
                            'title': 'Environment Variables',
                            'type': 'array',
                            'items': {
                                '$id': 'http://platform.cosmoconsult.com/ontology/EnvironmentVariable',
                                'type': 'object',
                                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FEnvironmentVariable' }],
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': false,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'title': 'Environment Variable',
                                'properties': {
                                    'value': {
                                        'title': 'Value',
                                        'description': "The value of the quantitative value or property value node.\\n\\n* For [[QuantitativeValue]] and [[MonetaryAmount]], the recommended type for values is 'Number'.\\n* For [[PropertyValue]], it can be 'Text;', 'Number', 'Boolean', or 'StructuredValue'.\\n* Use values from 0123456789 (Unicode 'DIGIT ZERO' (U+0030) to 'DIGIT NINE' (U+0039)) rather than superficially similiar Unicode symbols.\\n* Use '.' (Unicode 'FULL STOP' (U+002E)) rather than ',' to indicate a decimal point. Avoid using these symbols as a readability separator.",
                                        'type': 'string',
                                        'links': [{ 'href': 'http://schema.org/value', 'rel': 'canonical' }],
                                    },
                                    'key': {
                                        'title': 'Key',
                                        'type': 'string',
                                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/key', 'rel': 'canonical' }],
                                    },
                                },
                                'required': ['value', 'key'],
                            },
                            'cp_ui': { 'sortOrderForm': 56 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/environmentVariables', 'rel': 'canonical' }],
                        },
                        'googleAnalyticsTrackingId': {
                            'title': 'Google Analytics Tracking Id',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 57 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/googleAnalyticsTrackingId', 'rel': 'canonical' }],
                        },
                        'initScript': {
                            'title': 'Init Script',
                            'type': 'string',
                            'format': 'cp:code',
                            'cp_ui': { 'sortOrderForm': 58 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/initScript', 'rel': 'canonical' }],
                        },
                        'msalAuthAuthority': {
                            'title': 'Msal Auth Authority',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 51 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthAuthority', 'rel': 'canonical' }],
                        },
                        'msalAuthAuthorityMetadata': {
                            'title': 'Msal Auth Authority Metadata',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 50 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthAuthorityMetadata', 'rel': 'canonical' }],
                        },
                        'msalAuthClientId': {
                            'title': 'Msal Auth Client Id',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 48 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthClientId', 'rel': 'canonical' }],
                        },
                        'msalAuthJourneyEditProfile': {
                            'title': 'Msal Auth Journey Edit Profile',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 59 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthJourneyEditProfile', 'rel': 'canonical' }],
                        },
                        'msalAuthJourneyLinkAccount': {
                            'title': 'Msal Auth Journey Link Account',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 60 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthJourneyLinkAccount', 'rel': 'canonical' }],
                        },
                        'msalAuthJourneyReset': {
                            'title': 'Msal Auth Journey Reset',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 61 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthJourneyReset', 'rel': 'canonical' }],
                        },
                        'msalAuthJourneySignIn': {
                            'title': 'Msal Auth Journey Sign In',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 62 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthJourneySignIn', 'rel': 'canonical' }],
                        },
                        'msalAuthScopes': {
                            'title': 'Msal Auth Scopes',
                            'type': 'array',
                            'items': { 'type': 'string' },
                            'cp_ui': { 'sortOrderForm': 49 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthScopes', 'rel': 'canonical' }],
                        },
                        'msalIgnoreCodes': {
                            'title': 'Msal Ignore Codes',
                            'type': 'array',
                            'items': { 'type': 'string' },
                            'cp_ui': { 'sortOrderForm': 63 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalIgnoreCodes', 'rel': 'canonical' }],
                        },
                        'msalReloadCodes': {
                            'title': 'Msal Reload Codes',
                            'type': 'array',
                            'items': { 'type': 'string' },
                            'cp_ui': { 'sortOrderForm': 64 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalReloadCodes', 'rel': 'canonical' }],
                        },
                        'searchServices': {
                            'title': 'Search Services',
                            'type': 'array',
                            'items': {
                                '$id': 'http://platform.cosmoconsult.com/ontology/SearchService',
                                'type': 'object',
                                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSearchService' }],
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': false,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'title': 'Search Service',
                                'properties': {
                                    'searchIndexes': {
                                        'title': 'Search Indexes',
                                        'type': 'array',
                                        'items': { 'type': 'string' },
                                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/searchIndexes', 'rel': 'canonical' }],
                                    },
                                    'url': {
                                        'title': 'Url',
                                        'description': '<div>Absolute URL of the item, if there is a URL that can be used 1:1 for this item (as a reference).</div>',
                                        'type': 'string',
                                        'format': 'uri',
                                        'links': [{ 'href': 'http://schema.org/url', 'rel': 'canonical' }],
                                    },
                                },
                            },
                            'cp_ui': { 'sortOrderForm': 65 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/searchServices', 'rel': 'canonical' }],
                        },
                        'supportedCpCultures': {
                            'title': 'Supported Cp Cultures',
                            'type': 'array',
                            'items': {
                                '$id': 'http://platform.cosmoconsult.com/ontology/CpCulture',
                                'type': 'object',
                                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpCulture' }],
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': false,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'title': 'Cp Culture',
                                'description': 'A culture based on RFC 4646',
                                'properties': { 'identifier': { 'type': 'string', 'title': 'Cp Culture' } },
                            },
                            'cp_ui': { 'sortOrderForm': 66 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/supportedCpCultures', 'rel': 'canonical' }],
                        },
                        'disableDarkMode': {
                            'title': 'Disable Dark Mode',
                            'type': 'boolean',
                            'cp_ui': { 'sortOrderForm': 67 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/disableDarkMode', 'rel': 'canonical' }],
                        },
                        'disableSearchEngineIndexing': {
                            'title': 'Disable Search Engine Indexing',
                            'type': 'boolean',
                            'cp_ui': { 'sortOrderForm': 68 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/disableSearchEngineIndexing', 'rel': 'canonical' }],
                        },
                        'name': {
                            'title': 'Name (Title or Designation)',
                            'description': 'The name of the thing or subject.',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 69 },
                            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                        },
                        'url': {
                            'title': 'Url',
                            'description': '<div>Absolute URL of the item, if there is a URL that can be used 1:1 for this item (as a reference).</div>',
                            'type': 'string',
                            'format': 'uri',
                            'cp_ui': { 'sortOrderForm': 70 },
                            'links': [{ 'href': 'http://schema.org/url', 'rel': 'canonical' }],
                        },
                    },
                    'required': [
                        'apiKey',
                        'copyright',
                        'msalAuthAuthority',
                        'msalAuthClientId',
                        'msalAuthJourneyEditProfile',
                        'msalAuthJourneyLinkAccount',
                        'msalAuthJourneyReset',
                        'msalAuthJourneySignIn',
                        'name',
                        'baseContainerImage',
                    ],
                    'cp_ui': { 'sortOrderForm': 33 },
                },
                'stagingConfiguration': {
                    'title': 'Staging Configuration',
                    '$id': 'http://platform.cosmoconsult.com/ontology/CpaConfiguration',
                    'type': 'object',
                    'links': [
                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaConfiguration' },
                        { 'href': 'http://platform.cosmoconsult.com/ontology/stagingConfiguration', 'rel': 'canonical' },
                    ],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'properties': {
                        'apiKey': {
                            'title': 'Api Key',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 52 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/apiKey', 'rel': 'canonical' }],
                        },
                        'assetFiles': {
                            'title': 'Asset Files',
                            'type': 'array',
                            'items': {
                                '$id': 'http://platform.cosmoconsult.com/ontology/KeyValue',
                                'type': 'object',
                                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FKeyValue' }],
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': false,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'title': 'Key Value',
                                'properties': {
                                    'value': {
                                        'title': 'Value',
                                        'description': "The value of the quantitative value or property value node.\\n\\n* For [[QuantitativeValue]] and [[MonetaryAmount]], the recommended type for values is 'Number'.\\n* For [[PropertyValue]], it can be 'Text;', 'Number', 'Boolean', or 'StructuredValue'.\\n* Use values from 0123456789 (Unicode 'DIGIT ZERO' (U+0030) to 'DIGIT NINE' (U+0039)) rather than superficially similiar Unicode symbols.\\n* Use '.' (Unicode 'FULL STOP' (U+002E)) rather than ',' to indicate a decimal point. Avoid using these symbols as a readability separator.",
                                        'type': 'string',
                                        'format': 'data-url',
                                        'links': [{ 'href': 'http://schema.org/value', 'rel': 'canonical' }],
                                    },
                                    'key': {
                                        'title': 'Key',
                                        'type': 'string',
                                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/key', 'rel': 'canonical' }],
                                    },
                                },
                            },
                            'cp_ui': { 'sortOrderForm': 53 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/assetFiles', 'rel': 'canonical' }],
                        },
                        'baseContainerImage': {
                            'title': 'Base Container Image',
                            '$id': 'http://platform.cosmoconsult.com/ontology/ContainerImage',
                            'type': 'object',
                            'links': [
                                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FContainerImage' },
                                { 'href': 'http://platform.cosmoconsult.com/ontology/baseContainerImage', 'rel': 'canonical' },
                            ],
                            'cp_handledByApiGateway': false,
                            'cp_disableTrash': false,
                            'cp_disableLocalization': false,
                            'cp_disableVersions': false,
                            'cp_disableClone': false,
                            'cp_disableLazyLoading': false,
                            'patternProperties': { '^[_@].*$': {} },
                            'additionalProperties': false,
                            'properties': {
                                'version': {
                                    'title': 'Version',
                                    'description': '<div>The version of the container image.</div>',
                                    'type': 'string',
                                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/version', 'rel': 'canonical' }],
                                },
                                'identifier': {
                                    'title': 'Identifier',
                                    'description': '<div>The name of the container image.</div>',
                                    'type': 'string',
                                    'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
                                },
                            },
                            'required': ['identifier'],
                            'cp_ui': { 'sortOrderForm': 54 },
                        },
                        'copyright': {
                            'title': 'Copyright',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 55 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/copyright', 'rel': 'canonical' }],
                        },
                        'environmentVariables': {
                            'title': 'Environment Variables',
                            'type': 'array',
                            'items': {
                                '$id': 'http://platform.cosmoconsult.com/ontology/EnvironmentVariable',
                                'type': 'object',
                                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FEnvironmentVariable' }],
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': false,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'title': 'Environment Variable',
                                'properties': {
                                    'value': {
                                        'title': 'Value',
                                        'description': "The value of the quantitative value or property value node.\\n\\n* For [[QuantitativeValue]] and [[MonetaryAmount]], the recommended type for values is 'Number'.\\n* For [[PropertyValue]], it can be 'Text;', 'Number', 'Boolean', or 'StructuredValue'.\\n* Use values from 0123456789 (Unicode 'DIGIT ZERO' (U+0030) to 'DIGIT NINE' (U+0039)) rather than superficially similiar Unicode symbols.\\n* Use '.' (Unicode 'FULL STOP' (U+002E)) rather than ',' to indicate a decimal point. Avoid using these symbols as a readability separator.",
                                        'type': 'string',
                                        'links': [{ 'href': 'http://schema.org/value', 'rel': 'canonical' }],
                                    },
                                    'key': {
                                        'title': 'Key',
                                        'type': 'string',
                                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/key', 'rel': 'canonical' }],
                                    },
                                },
                                'required': ['value', 'key'],
                            },
                            'cp_ui': { 'sortOrderForm': 56 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/environmentVariables', 'rel': 'canonical' }],
                        },
                        'googleAnalyticsTrackingId': {
                            'title': 'Google Analytics Tracking Id',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 57 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/googleAnalyticsTrackingId', 'rel': 'canonical' }],
                        },
                        'initScript': {
                            'title': 'Init Script',
                            'type': 'string',
                            'format': 'cp:code',
                            'cp_ui': { 'sortOrderForm': 58 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/initScript', 'rel': 'canonical' }],
                        },
                        'msalAuthAuthority': {
                            'title': 'Msal Auth Authority',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 51 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthAuthority', 'rel': 'canonical' }],
                        },
                        'msalAuthAuthorityMetadata': {
                            'title': 'Msal Auth Authority Metadata',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 50 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthAuthorityMetadata', 'rel': 'canonical' }],
                        },
                        'msalAuthClientId': {
                            'title': 'Msal Auth Client Id',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 48 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthClientId', 'rel': 'canonical' }],
                        },
                        'msalAuthJourneyEditProfile': {
                            'title': 'Msal Auth Journey Edit Profile',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 59 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthJourneyEditProfile', 'rel': 'canonical' }],
                        },
                        'msalAuthJourneyLinkAccount': {
                            'title': 'Msal Auth Journey Link Account',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 60 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthJourneyLinkAccount', 'rel': 'canonical' }],
                        },
                        'msalAuthJourneyReset': {
                            'title': 'Msal Auth Journey Reset',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 61 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthJourneyReset', 'rel': 'canonical' }],
                        },
                        'msalAuthJourneySignIn': {
                            'title': 'Msal Auth Journey Sign In',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 62 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthJourneySignIn', 'rel': 'canonical' }],
                        },
                        'msalAuthScopes': {
                            'title': 'Msal Auth Scopes',
                            'type': 'array',
                            'items': { 'type': 'string' },
                            'cp_ui': { 'sortOrderForm': 49 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthScopes', 'rel': 'canonical' }],
                        },
                        'msalIgnoreCodes': {
                            'title': 'Msal Ignore Codes',
                            'type': 'array',
                            'items': { 'type': 'string' },
                            'cp_ui': { 'sortOrderForm': 63 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalIgnoreCodes', 'rel': 'canonical' }],
                        },
                        'msalReloadCodes': {
                            'title': 'Msal Reload Codes',
                            'type': 'array',
                            'items': { 'type': 'string' },
                            'cp_ui': { 'sortOrderForm': 64 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalReloadCodes', 'rel': 'canonical' }],
                        },
                        'searchServices': {
                            'title': 'Search Services',
                            'type': 'array',
                            'items': {
                                '$id': 'http://platform.cosmoconsult.com/ontology/SearchService',
                                'type': 'object',
                                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSearchService' }],
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': false,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'title': 'Search Service',
                                'properties': {
                                    'searchIndexes': {
                                        'title': 'Search Indexes',
                                        'type': 'array',
                                        'items': { 'type': 'string' },
                                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/searchIndexes', 'rel': 'canonical' }],
                                    },
                                    'url': {
                                        'title': 'Url',
                                        'description': '<div>Absolute URL of the item, if there is a URL that can be used 1:1 for this item (as a reference).</div>',
                                        'type': 'string',
                                        'format': 'uri',
                                        'links': [{ 'href': 'http://schema.org/url', 'rel': 'canonical' }],
                                    },
                                },
                            },
                            'cp_ui': { 'sortOrderForm': 65 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/searchServices', 'rel': 'canonical' }],
                        },
                        'supportedCpCultures': {
                            'title': 'Supported Cp Cultures',
                            'type': 'array',
                            'items': {
                                '$id': 'http://platform.cosmoconsult.com/ontology/CpCulture',
                                'type': 'object',
                                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpCulture' }],
                                'cp_handledByApiGateway': false,
                                'cp_disableTrash': false,
                                'cp_disableLocalization': false,
                                'cp_disableVersions': false,
                                'cp_disableClone': false,
                                'cp_disableLazyLoading': false,
                                'patternProperties': { '^[_@].*$': {} },
                                'additionalProperties': false,
                                'title': 'Cp Culture',
                                'description': 'A culture based on RFC 4646',
                                'properties': { 'identifier': { 'type': 'string', 'title': 'Cp Culture' } },
                            },
                            'cp_ui': { 'sortOrderForm': 66 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/supportedCpCultures', 'rel': 'canonical' }],
                        },
                        'disableDarkMode': {
                            'title': 'Disable Dark Mode',
                            'type': 'boolean',
                            'cp_ui': { 'sortOrderForm': 67 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/disableDarkMode', 'rel': 'canonical' }],
                        },
                        'disableSearchEngineIndexing': {
                            'title': 'Disable Search Engine Indexing',
                            'type': 'boolean',
                            'cp_ui': { 'sortOrderForm': 68 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/disableSearchEngineIndexing', 'rel': 'canonical' }],
                        },
                        'name': {
                            'title': 'Name (Title or Designation)',
                            'description': 'The name of the thing or subject.',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 69 },
                            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                        },
                        'url': {
                            'title': 'Url',
                            'description': '<div>Absolute URL of the item, if there is a URL that can be used 1:1 for this item (as a reference).</div>',
                            'type': 'string',
                            'format': 'uri',
                            'cp_ui': { 'sortOrderForm': 70 },
                            'links': [{ 'href': 'http://schema.org/url', 'rel': 'canonical' }],
                        },
                    },
                    'required': [
                        'apiKey',
                        'copyright',
                        'msalAuthAuthority',
                        'msalAuthClientId',
                        'msalAuthJourneyEditProfile',
                        'msalAuthJourneyLinkAccount',
                        'msalAuthJourneyReset',
                        'msalAuthJourneySignIn',
                        'name',
                        'baseContainerImage',
                    ],
                    'cp_ui': { 'sortOrderForm': 32 },
                },
            },
            'cp_ui': { 'sortOrderForm': 45 },
        },
        'dataServiceEndpoint': {
            'title': 'Data Service Endpoint',
            'description': '<div>The data service endpoint of COSMO Platform (CP) which is responsible for various data operations, e.g. CRUD operations for all COSMO Platform entities.</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpaDataEndpoint',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaDataEndpoint' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/dataServiceEndpoint', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': true,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Cpa Data Endpoint' } },
            'cp_ui': { 'sortOrderForm': 47 },
        },
        'headerVisualLarge': {
            'title': 'Header Visual Large',
            'type': 'string',
            'format': 'data-url',
            'cp_ui': { 'sortOrderForm': 49 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/headerVisualLarge', 'rel': 'canonical' }],
        },
        'headerVisualSmall': {
            'title': 'Header Visual Small',
            'type': 'string',
            'format': 'data-url',
            'cp_ui': { 'sortOrderForm': 50 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/headerVisualSmall', 'rel': 'canonical' }],
        },
        'metaServiceEndpoint': {
            'title': 'Meta Service Endpoint',
            'description': '<div>The meta service endpoint of COSMO Platform (CP) which provides various metadata, e.g. schemas for all entities of the COSMO Platform.</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/CpaDataEndpoint',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpaDataEndpoint' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/metaServiceEndpoint', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': true,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'Cpa Data Endpoint' } },
            'cp_ui': { 'sortOrderForm': 46 },
        },
        'colorPalette': {
            'title': 'Color Palette',
            'description': '<div>Color palette according to corporate design.</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/ColorPalette',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FColorPalette' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/colorPalette', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'colorWhite': {
                    'title': 'Color White',
                    'type': 'string',
                    'format': 'cp:colorAll',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/colorWhite', 'rel': 'canonical' }],
                },
                'colorBlack': {
                    'title': 'Color Black',
                    'type': 'string',
                    'format': 'cp:colorAll',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/colorBlack', 'rel': 'canonical' }],
                },
                'colorPrimary': {
                    'title': 'Color Primary',
                    'type': 'string',
                    'format': 'cp:colorAll',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/colorPrimary', 'rel': 'canonical' }],
                },
                'colorSecondary': {
                    'title': 'Color Secondary',
                    'type': 'string',
                    'format': 'cp:colorAll',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/colorSecondary', 'rel': 'canonical' }],
                },
                'colorDarkRed': {
                    'title': 'Color Dark Red',
                    'type': 'string',
                    'format': 'cp:colorAll',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/colorDarkRed', 'rel': 'canonical' }],
                },
                'colorDarkBlue': {
                    'title': 'Color Dark Blue',
                    'type': 'string',
                    'format': 'cp:colorAll',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/colorDarkBlue', 'rel': 'canonical' }],
                },
                'colorDarkGreen': {
                    'title': 'Color Dark Green',
                    'type': 'string',
                    'format': 'cp:colorAll',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/colorDarkGreen', 'rel': 'canonical' }],
                },
                'colorDarkAccent1': {
                    'title': 'Color Dark Accent 1',
                    'type': 'string',
                    'format': 'cp:colorAll',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/colorDarkAccent1', 'rel': 'canonical' }],
                },
                'colorDarkGrey': {
                    'title': 'Color Dark Grey',
                    'type': 'string',
                    'format': 'cp:colorAll',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/colorDarkGrey', 'rel': 'canonical' }],
                },
                'colorLightRed': {
                    'title': 'Color Light Red',
                    'type': 'string',
                    'format': 'cp:colorAll',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/colorLightRed', 'rel': 'canonical' }],
                },
                'colorLightBlue': {
                    'title': 'Color Light Blue',
                    'type': 'string',
                    'format': 'cp:colorAll',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/colorLightBlue', 'rel': 'canonical' }],
                },
                'colorLightGreen': {
                    'title': 'Color Light Green',
                    'type': 'string',
                    'format': 'cp:colorAll',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/colorLightGreen', 'rel': 'canonical' }],
                },
                'colorLightAccent1': {
                    'title': 'Color Light Accent 1',
                    'type': 'string',
                    'format': 'cp:colorAll',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/colorLightAccent1', 'rel': 'canonical' }],
                },
                'colorLightGray': {
                    'title': 'Color Light Gray',
                    'type': 'string',
                    'format': 'cp:colorAll',
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/colorLightGray', 'rel': 'canonical' }],
                },
            },
            'required': [
                'colorWhite',
                'colorBlack',
                'colorPrimary',
                'colorSecondary',
                'colorDarkRed',
                'colorDarkBlue',
                'colorDarkGreen',
                'colorDarkAccent1',
                'colorDarkGrey',
                'colorLightRed',
                'colorLightBlue',
                'colorLightGreen',
                'colorLightAccent1',
                'colorLightGray',
            ],
            'cp_ui': { 'sortOrderForm': 48 },
        },
        'disableProvisioning': {
            'title': 'Disable Provisioning',
            'description': "<div>Disables CPA provisioning, so it won't be automatically deployed.</div>",
            'type': 'boolean',
            'cp_ui': { 'sortOrderForm': 52 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/disableProvisioning', 'rel': 'canonical' }],
        },
        'loadingLogo': {
            'title': 'Loading Logo',
            'description': '<div>Icon is used on the loading screen</div>',
            'type': 'string',
            'format': 'data-url',
            'cp_ui': { 'sortOrderForm': 53 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/loadingLogo', 'rel': 'canonical' }],
        },
        'headerLogo': {
            'title': 'Header Logo',
            'description': '<div>\n<div>Icon is used in the app header</div>\n</div>',
            'type': 'string',
            'format': 'data-url',
            'cp_ui': { 'sortOrderForm': 55 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/headerLogo', 'rel': 'canonical' }],
        },
        'footerLogo': {
            'title': 'Footer Logo',
            'description': '<div>\n<div>Icon is used in the app footer</div>\n</div>',
            'type': 'string',
            'format': 'data-url',
            'cp_ui': { 'sortOrderForm': 56 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/footerLogo', 'rel': 'canonical' }],
        },
        'favicon': {
            'title': 'Favicon',
            'description': '<div>\n<div>Icon is used as favicon, must be in .ico format</div>\n</div>',
            'type': 'string',
            'format': 'data-url',
            'cp_ui': { 'sortOrderForm': 57 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/favicon', 'rel': 'canonical' }],
        },
        'manifestIcons': {
            'title': 'Manifest Icons',
            'description': '<div>A list of icons used in the app manifest</div>',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/ManifestIcon',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FManifestIcon' }],
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'Manifest Icon',
                'properties': {
                    'size': {
                        'title': 'Size',
                        'description': '<div>Image dimensions, for example - 192x192</div>',
                        'type': 'string',
                        'links': [{ 'href': 'http://schema.org/size', 'rel': 'canonical' }],
                    },
                    'icon': {
                        'title': 'Icon',
                        'description': '<div>Manifest icon</div>',
                        'type': 'string',
                        'format': 'data-url',
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/icon', 'rel': 'canonical' }],
                    },
                    'purpose': {
                        'title': 'Purpose',
                        'description': '<div>Defines the purpose of the image - monochrome, maskable or any</div>',
                        'type': 'string',
                        'default': 'any',
                        'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/purpose', 'rel': 'canonical' }],
                    },
                },
                'required': ['size', 'icon', 'purpose'],
            },
            'cp_ui': { 'sortOrderForm': 58 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/manifestIcon', 'rel': 'canonical' }],
        },
        'backgroundLogo': {
            'title': 'Background Logo',
            'description': "<div>Icon is used in the background for app's pages</div>",
            'type': 'string',
            'format': 'data-url',
            'cp_ui': { 'sortOrderForm': 54 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/backgroundLogo', 'rel': 'canonical' }],
        },
        'userProfileLabel': {
            'title': 'User Profile Label',
            'type': 'string',
            'default': 'COSMOs',
            'cp_ui': { 'sortOrderForm': 44 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/userProfileLabel', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 41 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'alternateName': {
            'title': 'Alternate Name',
            'description': 'An alias for the item.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 43 },
            'links': [{ 'href': 'http://schema.org/alternateName', 'rel': 'canonical' }],
        },
        'image': {
            'title': 'Image',
            'description': 'An image of the item. This can be a [[URL]] or a fully described [[ImageObject]].',
            'type': 'string',
            'format': 'data-url',
            'cp_ui': { 'sortOrderForm': 51 },
            'links': [{ 'href': 'http://schema.org/image', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 42 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'required': ['userProfileLabel', 'name', 'dataServiceEndpoint', 'metaServiceEndpoint', 'colorPalette', 'configurations'],
};
export const CpaSchema = Schema;
