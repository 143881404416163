import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import * as _ from 'lodash';
import { DataItemProperties, IJSONSchema, Schemas } from '@cp/base-types';
import { CommandBarButton, IPickerItemProps, ITag, MessageBarType, TagItem, TagPicker, ValidationState } from '@fluentui/react';
import { useBoolean, usePrevious } from '@fluentui/react-hooks';
import Form, { ArrayFieldTemplateProps, ErrorSchema, IdSchema, Registry, UiSchema, utils } from '@rjsf/core';
import { useTranslation } from 'react-i18next';
import { cloneDeepWithMetadata, hasLocalizableFieldInSchema } from '@cp/base-utils';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { IGlobalState } from '@cpa/base-core/store';
import { axiosDictionary, executeAggregationTemplate } from '@cpa/base-core/api';
import { isRelationSchema } from '@cp/base-utils';
import { EndpointContext, FormContext, ItemInfoContext, PathContext } from '@cpa/base-core/constants';
import { IDataItem, IDataUrlDetails } from '@cpa/base-core/types';
import { getDataUrlForLookup, getMatchingPageByDataUrl } from '@cpa/base-core/helpers';
import { useLoadableData } from '@cpa/base-core/hooks';

import ExpandButton from '../../../ExpandButton/ExpandButton';
import TitleField, { TitleFieldType } from '../TitleField/TitleField';
import DescriptionField from '../DescriptionField/DescriptionField';
import HoverTooltip from '../../../HoverTooltip/HoverTooltip';
import ChoiceGroup, { IOption } from '../SelectWidget/ChoiceGroup/ChoiceGroup';
import LoadingArea from '../../../LoadingArea/LoadingArea';

import styles from './ArrayFieldTemplate.module.scss';
import ArrayItemButtons from './components/ArrayItemButtons/ArrayItemButtons';
import TreeView from './components/TreeView/TreeView';

const { isMultiSelect, getDefaultRegistry } = utils;

interface IArrayItemControls {
  vertical: boolean;
  allowReordering: boolean;
  allowDelete: boolean;
}

const getArrayItemControls = (
  schema: IJSONSchema,
  type?: 'add' | 'edit',
  baseLanguage?: string | null,
  currentLanguage?: string,
  hasLocalizations?: boolean
): IArrayItemControls => {
  const isAnyOf = !!schema.items?.anyOf;
  const isObject = schema.items?.type === 'object';
  const isRelated = schema.items && isRelationSchema(schema.items);
  const isLocalizationMode = !!baseLanguage && !!currentLanguage && baseLanguage !== currentLanguage;

  const allowReordering =
    type === 'add' ||
    !schema.items ||
    (schema.items.type !== 'object' && !schema.items.anyOf) ||
    !hasLocalizableFieldInSchema(schema.items) ||
    (!hasLocalizations && !isLocalizationMode);

  return {
    vertical: isAnyOf || (isObject && !isRelated),
    allowReordering: allowReordering,
    allowDelete: allowReordering || !isLocalizationMode,
  };
};

const ArrayFieldTemplate = (props: ArrayFieldTemplateProps): JSX.Element => {
  const { schema, registry = getDefaultRegistry() } = props;

  if (
    (schema?.format === 'cp:TileOption' ||
      (schema?.items && typeof schema.items === 'object' && (schema.items as IJSONSchema).format === 'cp:TileOption')) &&
    isRelationSchema(schema.items as IJSONSchema)
  ) {
    return <ChoiceGroupArrayFieldTemplate {...props} />;
  }

  if (schema?.format === 'cp:tags' || (schema?.items && typeof schema.items === 'object' && (schema.items as IJSONSchema).format === 'cp:tags')) {
    return <TagsArrayFieldTemplate autocomplete={false} {...props} />;
  }

  if (
    schema?.format === 'cp:tags:autocomplete' ||
    (schema?.items && typeof schema.items === 'object' && (schema.items as IJSONSchema).format === 'cp:tags:autocomplete')
  ) {
    return <TagsArrayFieldTemplate autocomplete={true} {...props} />;
  }

  if (
    schema?.format === 'propertyTreeView' ||
    (schema?.items && typeof schema.items === 'object' && (schema.items as IJSONSchema).format === 'propertyTreeView')
  ) {
    return <TreeViewArrayFieldTemplate {...props} />;
  }

  const hasLocalizations = _.get(props.registry, `rootFormData.${DataItemProperties.HAS_LOCALIZATIONS}`) === true;
  if (isMultiSelect(schema, (registry as unknown as { rootSchema: { [name: string]: string } }).rootSchema)) {
    return <DefaultFixedArrayFieldTemplate {...props} hasLocalizations={hasLocalizations} />;
  } else {
    return <DefaultNormalArrayFieldTemplate {...props} hasLocalizations={hasLocalizations} />;
  }
};

interface ArrayFieldTitleProps {
  idSchema: IdSchema;
  schema: IJSONSchema;
  registry: Registry;
  title: string;
  required: boolean;
}

const ArrayFieldTitle = ({ idSchema, schema, registry, title, required }: ArrayFieldTitleProps): JSX.Element | null => {
  if (!title) {
    return null;
  }

  return (
    <TitleField
      title={title}
      required={required}
      localizable={schema?.cp_localizable}
      schema={schema}
      registry={registry}
      type={idSchema.$id === 'root' ? TitleFieldType.Root : TitleFieldType.Object}
    />
  );
};

interface ArrayFieldDescriptionProps {
  idSchema: IdSchema;
  description: string;
  detailed?: boolean;
  hasDetails?: boolean;
}

const ArrayFieldDescription = ({ idSchema, description, detailed, hasDetails }: ArrayFieldDescriptionProps): JSX.Element | null => {
  if (!description) {
    return null;
  }

  const id = `${idSchema.$id}__description`;
  return <DescriptionField id={id} description={description} detailed={detailed} hasDetails={hasDetails} />;
};

const DefaultArrayItem = (
  props: Omit<ArrayFieldTemplateProps['items'][0], 'key'> & {
    arrowOptions: IArrayItemControls;
    even: boolean;
    defaultExpanded?: boolean;
    arraySchema: IJSONSchema;
    children: React.ReactElement;
    onClone: (index: number) => void;
    uiSchema?: UiSchema;
  }
): JSX.Element => {
  const dark = useSelector((state: IGlobalState) => state.settings.darkMode);
  const [expanded, setExpanded] = useState(!!props.defaultExpanded);

  const pathContextValue = useContext(PathContext);

  return (
    <div dir="ltr">
      <div className={classNames(styles.row, { [styles.even]: props.even && !dark, [styles.evenDark]: props.even && dark })}>
        <div
          className={classNames(styles.content, {
            [styles.contentExpanded]: expanded,
            [styles.contentCollapsed]: !expanded,
          })}
        >
          <div>
            {React.Children.map(props.children, (child) => (
              <PathContext.Provider value={pathContextValue + `[${props.index}]`}>
                {React.cloneElement(child, {
                  uiSchema: {
                    ...props.uiSchema,
                    defaultExpanded: props.defaultExpanded,
                    isArrayItem: true,
                    isLocalizableArray: !!props.arraySchema?.cp_localizable,
                    onExpand: setExpanded,
                    index: props.index,
                  },
                })}
              </PathContext.Provider>
            ))}
          </div>
        </div>
        <ArrayItemButtons
          index={props.index}
          expanded={expanded}
          verticalArrows={props.arrowOptions.vertical}
          disabled={props.disabled}
          readonly={props.readonly}
          allowReordering={props.arrowOptions.allowReordering}
          allowDelete={props.arrowOptions.allowDelete}
          hasMoveUp={props.hasMoveUp}
          hasMoveDown={props.hasMoveDown}
          onReorderClick={props.onReorderClick}
          onClone={() => props.onClone(props.index)}
          onDropIndexClick={props.onDropIndexClick}
        />
      </div>
    </div>
  );
};

const DefaultArrayItems: React.FC<
  Pick<ArrayFieldTemplateProps, 'items'> & {
    arraySchema: IJSONSchema;
    onClone: (index: number) => void;
    hasLocalizations: boolean;
  }
> = ({ items, arraySchema, onClone, hasLocalizations }) => {
  const { current: initialAmountOfItems } = useRef(items.length);
  const previousItemsCount: number | undefined = usePrevious(items.length);
  const hasNewItems: boolean = previousItemsCount !== undefined && items.length > previousItemsCount;

  const itemInfoContext = useContext(ItemInfoContext);
  const arrowOptions = useMemo<IArrayItemControls>(
    () => getArrayItemControls(arraySchema, itemInfoContext?.type, itemInfoContext?.baseLanguage, itemInfoContext?.currentLanguage, hasLocalizations),
    [arraySchema, itemInfoContext?.baseLanguage, itemInfoContext?.currentLanguage, itemInfoContext?.type, hasLocalizations]
  );
  const arrowOptionsForNewItems = useMemo<IArrayItemControls>(
    () => ({
      ...arrowOptions,
      allowDelete: true,
    }),
    [arrowOptions]
  );

  return (
    <>
      {items?.map((itemProps, index) => (
        <DefaultArrayItem
          {...itemProps}
          key={index}
          defaultExpanded={index === items.length - 1 && hasNewItems}
          arrowOptions={index >= initialAmountOfItems ? arrowOptionsForNewItems : arrowOptions}
          even={index % 2 === 0}
          onClone={onClone}
          arraySchema={arraySchema}
          readonly={itemProps.readonly || !!arraySchema?.items?.readOnly}
        />
      ))}
    </>
  );
};

const DefaultFixedArrayFieldTemplate = (
  props: ArrayFieldTemplateProps & {
    registry: Registry & {
      formRef?: Form<unknown>;
    };
    hasLocalizations: boolean;
  }
): JSX.Element => {
  const pathContextValue = useContext(PathContext);
  const [isExpanded, { toggle: toggleExpanded }] = useBoolean(props.uiSchema.defaultExpanded ?? false);
  const [t] = useTranslation();

  const lastNestedObject: string[] = useMemo(() => props.formData, [props.formData]);

  const handleClone = useCallback(
    (index: number): void => {
      const elementToClone = cloneDeepWithMetadata(props.formData[index]);
      lastNestedObject.splice(0, lastNestedObject.length, ...props.formData, elementToClone);
      const newFormState = _.set(
        cloneDeepWithMetadata((props.registry.formRef!.state as { formData: object }).formData),
        pathContextValue,
        lastNestedObject
      );
      props.registry.formRef?.setState({
        formData: newFormState,
      });
      props.registry.formRef?.onChange(
        newFormState,
        (
          props.registry.formRef.state as {
            errorSchema: ErrorSchema;
          }
        ).errorSchema || {}
      );
    },
    [lastNestedObject, pathContextValue, props.formData, props.registry.formRef]
  );

  return (
    <fieldset className={props.className}>
      <ExpandButton isExpanded={isExpanded} onClick={toggleExpanded} />

      <span className={styles.clickable} onClick={toggleExpanded}>
        <ArrayFieldTitle
          key={`array-field-title-${props.idSchema.$id}`}
          idSchema={props.idSchema}
          schema={props.schema as IJSONSchema}
          registry={props.registry}
          title={`${props.uiSchema['ui:title'] || props.title} (${props.items.length || 0})`}
          required={props.required}
        />
      </span>

      {(props.uiSchema['ui:description'] || props.schema.description) && (
        <div className="field-description" key={`field-description-${props.idSchema.$id}`}>
          {props.uiSchema['ui:description'] || props.schema.description}
        </div>
      )}

      {isExpanded && (
        <div style={{ marginLeft: 32, minWidth: 'calc(100% - 32px)', width: 'fit-content' }}>
          <div className="row array-item-list" key={`array-item-list-${props.idSchema.$id}`}>
            <DefaultArrayItems
              items={props.items}
              arraySchema={props.schema as IJSONSchema}
              onClone={handleClone}
              hasLocalizations={props.hasLocalizations}
            />
          </div>

          {props.canAdd && !((props.schema.items as IJSONSchema) || null)?.readOnly && (
            <span className={styles.right}>
              <HoverTooltip content={t('common.addItemTooltip', { name: props.schema.title })}>
                <CommandBarButton
                  style={{ height: '32px', backgroundColor: 'transparent' }}
                  iconProps={{ iconName: 'CalculatorAddition' }}
                  text={t('common.addItem')}
                  className="array-item-add"
                  onClick={props.onAddClick}
                  disabled={props.disabled || props.readonly}
                />
              </HoverTooltip>
            </span>
          )}
        </div>
      )}
    </fieldset>
  );
};

const DefaultNormalArrayFieldTemplate = (
  props: ArrayFieldTemplateProps & {
    registry: Registry & {
      formRef?: Form<unknown>;
    };
    hasLocalizations: boolean;
  }
): JSX.Element => {
  const pathContextValue = useContext(PathContext);
  const [isExpanded, { toggle: toggleExpanded }] = useBoolean(props.uiSchema.defaultExpanded ?? false);
  const [t] = useTranslation();

  const lastNestedObject: string[] = useMemo(() => props.formData, [props.formData]);

  const handleClone = useCallback(
    (index: number): void => {
      const elementToClone = cloneDeepWithMetadata(props.formData[index]);
      lastNestedObject.splice(0, lastNestedObject.length, ...props.formData, elementToClone);
      const newFormState = _.set(
        cloneDeepWithMetadata((props.registry.formRef!.state as { formData: object }).formData),
        pathContextValue,
        lastNestedObject
      );
      props.registry.formRef?.setState({
        formData: newFormState,
      });
      props.registry.formRef?.onChange(
        newFormState,
        (
          props.registry.formRef.state as {
            errorSchema: ErrorSchema;
          }
        ).errorSchema || {}
      );
    },
    [lastNestedObject, pathContextValue, props.formData, props.registry.formRef]
  );

  return (
    <>
      <div className={classNames(styles.titleWrapper, { [styles.multiline]: isExpanded })}>
        <div className={styles.labelWrapper}>
          <ExpandButton isExpanded={isExpanded} onClick={toggleExpanded} />
          <span className={styles.clickable} onClick={toggleExpanded}>
            <ArrayFieldTitle
              key={`array-field-title-${props.idSchema.$id}`}
              schema={props.schema as IJSONSchema}
              idSchema={props.idSchema}
              registry={props.registry}
              title={`${props.uiSchema['ui:title'] || props.title} (${props.items.length || 0})`}
              required={props.required}
            />
          </span>
        </div>

        {(props.uiSchema['ui:description'] || props.schema.description) && (
          <ArrayFieldDescription
            key={`array-field-description-${props.idSchema.$id}`}
            idSchema={props.idSchema}
            description={props.uiSchema['ui:description'] || props.schema.description}
            detailed={isExpanded}
            hasDetails
          />
        )}
      </div>
      {isExpanded && (
        <div style={{ marginLeft: 32, minWidth: 'calc(100% - 32px)', width: 'fit-content' }}>
          <DefaultArrayItems
            items={props.items}
            arraySchema={props.schema as IJSONSchema}
            onClone={handleClone}
            hasLocalizations={props.hasLocalizations}
          />

          {props.canAdd && !((props.schema.items as IJSONSchema) || null)?.readOnly && (
            <span className={styles.right}>
              <HoverTooltip content={t('common.addItemTooltip', { name: props.schema.title })}>
                <CommandBarButton
                  style={{ height: '32px' }}
                  iconProps={{ iconName: 'CalculatorAddition' }}
                  text={t('common.addItem')}
                  className={classNames('array-item-add', styles.button)}
                  onClick={props.onAddClick}
                  disabled={props.disabled || props.readonly}
                />
              </HoverTooltip>
            </span>
          )}
        </div>
      )}
    </>
  );
};

const ChoiceGroupArrayFieldTemplate = (props: ArrayFieldTemplateProps): JSX.Element => {
  const schema = props.schema as IJSONSchema;
  const registry = props.registry as Registry & {
    rootSchema?: IJSONSchema;
    rootFormData?: IDataItem;
    formRef?: Form<unknown>;
  };
  const { page } = useContext(FormContext);
  const itemInfoContext = useContext(ItemInfoContext);
  const pathContextValue = useContext(PathContext);
  const endpointIdentifierFromContext = useContext(EndpointContext);
  const pages = useSelector((state: IGlobalState) => state.app.pages);

  const lookupLink = useMemo(() => {
    return schema.items?.links?.find((link) => link.rel === 'collection');
  }, [schema]);

  const endpointIdentifier = lookupLink?.endpoint || endpointIdentifierFromContext;

  const [virtualData, setVirtualData] = useState<{ schema: IJSONSchema; items: IDataItem[]; totalItems: number }>();
  const wrappedGetDataUrl = useCallback(async (): Promise<IDataUrlDetails | null> => {
    if (!lookupLink || !schema) {
      return null;
    }

    return await getDataUrlForLookup(
      lookupLink,
      undefined,
      schema,
      pathContextValue,
      registry,
      undefined,
      itemInfoContext?.type,
      page,
      itemInfoContext?.showMessage,
      setVirtualData
    );
  }, [itemInfoContext?.showMessage, itemInfoContext?.type, lookupLink, page, pathContextValue, registry, schema]);

  const matchedPage: Schemas.CpaPage | undefined = useMemo(() => {
    if (!lookupLink) {
      return;
    }

    const url = new URL(lookupLink.href, window.location.origin);
    return getMatchingPageByDataUrl(url.pathname + url.search, pages)?.matched;
  }, [lookupLink, pages]);

  const {
    loadItems,
    isFetching: isFetchingLoadable,
    items: itemsLoadable,
    errors,
  } = useLoadableData(
    wrappedGetDataUrl,
    endpointIdentifier || axiosDictionary.appDataService,
    matchedPage?.groupPropertyJsonPath,
    matchedPage?.schemaUrl as string | undefined
  );

  useEffect(() => {
    if (errors.length && itemInfoContext) {
      for (const error of errors) {
        itemInfoContext.showMessage(error, MessageBarType.error);
      }
    }
  }, [errors, itemInfoContext]);

  useEffect(() => {
    // Initial items fetching
    loadItems();
    // eslint-disable-next-line
  }, [lookupLink]);

  const isFetching = virtualData ? false : isFetchingLoadable;
  const items = virtualData?.items ?? itemsLoadable;
  const options = useMemo<IOption[]>(
    () =>
      _.sortBy(
        items.map(
          (item): IOption => ({
            key: item.identifier!,
            text: item.name as string,
            disabled: false,
            iconName: item.image as string,
            description: item.description as string,
          })
        ),
        'text'
      ),
    [items]
  );
  const iconDisabled = schema.cp_disableIcon || schema.items?.cp_disableIcon || options.every((option) => !option.iconName);

  const onChoiceGroupChange = useCallback(
    (selectedKey?: string): void => {
      if (!selectedKey) {
        return;
      }
      const valueOrDefault: IDataItem[] = props.formData || [];

      const newFormState = _.set(
        cloneDeepWithMetadata((registry.formRef!.state as { formData: object }).formData),
        pathContextValue,
        valueOrDefault.some(({ identifier }) => identifier === selectedKey)
          ? valueOrDefault.filter(({ identifier }) => identifier !== selectedKey)
          : [...valueOrDefault, { identifier: selectedKey }]
      );

      registry.formRef?.setState({
        formData: newFormState,
      });
      registry.formRef?.onChange(
        newFormState,
        (
          registry.formRef.state as {
            errorSchema: ErrorSchema;
          }
        ).errorSchema || {}
      );
    },
    [pathContextValue, props.formData, registry.formRef]
  );

  if (isFetching) {
    return (
      <div style={{ height: 60 }}>
        <LoadingArea />
      </div>
    );
  }

  return (
    <div>
      {(!!props.uiSchema['ui:title'] || !!props.title) && (
        <TitleField
          title={`${props.uiSchema['ui:title'] || props.title} (${props.items.length || 0})`}
          type={TitleFieldType.Primitive}
          registry={props.registry}
          required={props.required}
        />
      )}

      {(props.uiSchema['ui:description'] || schema.description) && (
        <ArrayFieldDescription
          key={`array-field-description-${props.idSchema.$id}`}
          idSchema={props.idSchema}
          description={props.uiSchema['ui:description'] || schema.description}
          detailed
          hasDetails={false}
        />
      )}

      <ChoiceGroup
        multiple={true}
        disableIcon={iconDisabled}
        options={options}
        required={props.required}
        selectedKeys={(props.formData as IDataItem[]).map(({ identifier }) => identifier!)}
        disabled={props.disabled || props.readonly || schema.readOnly}
        onChange={onChoiceGroupChange}
      />
    </div>
  );
};

// TODO: Refactor
const TagsArrayFieldTemplate = (
  props: ArrayFieldTemplateProps & {
    autocomplete: boolean;
    registry: Registry & {
      formRef?: Form<unknown>;
    };
  }
): JSX.Element => {
  const [inputText, setInputText] = useState('');
  const selectedItems: ITag[] = useMemo(
    () => props.formData.map((item: string, index: string) => ({ name: item, key: item + index })),
    [props.formData]
  );
  const { page } = useContext(FormContext);
  const pathContextValue = useContext(PathContext);
  const onResolveSuggestions = useCallback(
    async (filter: string): Promise<ITag[]> => {
      if (!props.autocomplete) return [{ name: filter, key: filter }];
      const propertyName = _.toPath(pathContextValue).join('.');
      const propertyPath = _.toPath(propertyName);

      if (!page?.cpTypeUrl) return [];
      const response = await executeAggregationTemplate(
        page?.dataEndpoint?.identifier || axiosDictionary.appDataService,
        page.cpTypeUrl,
        'arrayfieldtemplate-resolve-suggestions',
        { propertyName: propertyName, propertyPath: propertyPath, filter: filter },
        undefined
      );

      return [{ key: inputText, name: inputText }, ...response.map((item) => ({ name: item._id as string, key: item.identifier as string }))];
    },
    [inputText, page?.cpTypeUrl, page?.dataEndpoint?.identifier, pathContextValue, props.autocomplete]
  );

  const lastNestedObject: string[] = useMemo(() => props.formData, [props.formData]);

  const onInputChange = useCallback(
    (value: string) => {
      if (value) {
        setInputText(value);
      }
      return value;
    },
    [setInputText]
  );

  const onItemSelected = useCallback(
    (selectedItem?: ITag) => {
      if (!selectedItem) {
        return null;
      }

      lastNestedObject.splice(0, lastNestedObject.length, ...props.formData, selectedItem.name);
      const newFormState = _.set(
        cloneDeepWithMetadata((props.registry.formRef!.state as { formData: object }).formData),
        pathContextValue,
        lastNestedObject
      );
      props.registry.formRef?.setState({
        formData: newFormState,
      });
      props.registry.formRef?.onChange(
        newFormState,
        (
          props.registry.formRef.state as {
            errorSchema: ErrorSchema;
          }
        ).errorSchema || {}
      );

      setInputText('');

      return selectedItem;
    },
    [lastNestedObject, pathContextValue, props.formData, props.registry.formRef]
  );

  const onTagRemove = (tagIndex: number): void => {
    lastNestedObject.splice(
      0,
      lastNestedObject.length,
      ...props.formData.slice(0, tagIndex),
      ...props.formData.slice(tagIndex + 1, props.formData.length)
    );

    const newFormState = _.setWith(
      cloneDeepWithMetadata((props.registry.formRef!.state as { formData: object }).formData),
      pathContextValue,
      lastNestedObject,
      (obj: unknown, src: unknown) => (Array.isArray(obj) ? src : undefined)
    );
    props.registry.formRef?.setState({
      formData: newFormState,
    });
    props.registry.formRef?.onChange(
      newFormState,
      (
        props.registry.formRef.state as {
          errorSchema: ErrorSchema;
        }
      ).errorSchema || {}
    );
  };

  const onRenderTag = useCallback(
    (tagProps: IPickerItemProps<ITag>): JSX.Element => {
      const onRemove = (): void => {
        tagProps.onRemoveItem?.();
        onTagRemove(tagProps.index);
      };
      return (
        <TagItem styles={{ text: { marginTop: 3 } }} key={tagProps.key} {...tagProps} onRemoveItem={onRemove}>
          {tagProps.item.name}
        </TagItem>
      );
    },
    [onTagRemove]
  );
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>): void => {
      const blockedKeys = ['Enter', 'Tab'];
      const blockedKeyCodes = [13, 9];
      if (inputText && (blockedKeys.includes(e.key) || blockedKeyCodes.includes(e.keyCode))) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [inputText]
  );

  const onCreateGenericItem = useCallback((input: string) => {
    setInputText('');
    const trimmedInput = input.trim();
    return {
      key: trimmedInput,
      name: trimmedInput,
    };
  }, []);

  return (
    <div onKeyDown={handleKeyDown}>
      {(!!props.uiSchema['ui:title'] || !!props.title) && (
        <TitleField
          title={`${props.uiSchema['ui:title'] || props.title} (${props.items.length || 0})`}
          type={TitleFieldType.Primitive}
          registry={props.registry}
          required={props.required}
        />
      )}

      {(props.uiSchema['ui:description'] || props.schema.description) && (
        <ArrayFieldDescription
          key={`array-field-description-${props.idSchema.$id}`}
          idSchema={props.idSchema}
          description={props.uiSchema['ui:description'] || props.schema.description}
          detailed
          hasDetails={false}
        />
      )}

      <TagPicker
        onResolveSuggestions={onResolveSuggestions}
        onValidateInput={(input) => (input && !/^ *$/.test(input) ? ValidationState.valid : ValidationState.invalid)}
        onInputChange={onInputChange}
        onItemSelected={onItemSelected}
        selectedItems={selectedItems}
        onRenderItem={onRenderTag}
        createGenericItem={onCreateGenericItem}
        resolveDelay={300}
      />
    </div>
  );
};

const TreeViewArrayFieldTemplate = (
  props: ArrayFieldTemplateProps & {
    registry: Registry & {
      formRef?: Form<unknown>;
    };
  }
): JSX.Element => {
  const [isExpanded, { toggle: toggleExpanded }] = useBoolean(props.uiSchema.defaultExpanded ?? false);
  const pathContextValue = useContext(PathContext);
  const lastNestedObject: Schemas.CpType['assignments'] = useMemo(() => props.formData, [props.formData]);
  const dataLanguage = useSelector((state: IGlobalState) => state.settings.dataLanguage);

  const handleTreeChange = useCallback(
    (fieldIndexes: Schemas.CpType['assignments'], formDataModifier?: (formData: object) => void): void => {
      if (!lastNestedObject || !fieldIndexes) return;
      lastNestedObject.splice(0, lastNestedObject.length, ...fieldIndexes);
      const currentFormData = cloneDeepWithMetadata((props.registry.formRef!.state as { formData: object }).formData);
      const newFormState = _.set(currentFormData, pathContextValue, lastNestedObject);
      formDataModifier?.(currentFormData);
      props.registry.formRef?.setState({
        formData: newFormState,
      });
      props.registry.formRef?.onChange(
        newFormState,
        (
          props.registry.formRef.state as {
            errorSchema: ErrorSchema;
          }
        ).errorSchema || {}
      );
    },
    [lastNestedObject, pathContextValue, props.registry.formRef]
  );

  const handleStepQuestionChange = useCallback(
    (question: string, index: number) => {
      const currentAnnotations = cloneDeepWithMetadata(
        props.registry.formRef!.state as {
          formData: {
            annotations: { annotationLanguage: { identifier: string | undefined }; annotationPropertyType: { identifier: string }; value: string }[];
          };
        }
      ).formData.annotations;
      const existingAnnotationIndex = currentAnnotations.findIndex((annotation) => {
        return (
          annotation.annotationPropertyType.identifier === 'cp:wizardStepQuestion' &&
          annotation.annotationLanguage.identifier === dataLanguage?.split('-')[0]
        );
      });
      if (existingAnnotationIndex !== -1) {
        const currentValue = JSON.parse(currentAnnotations[existingAnnotationIndex].value) as (string | null)[];
        currentValue[index] = question || null;
        currentAnnotations[existingAnnotationIndex].value = JSON.stringify(currentValue);
      } else {
        const arrayToInsert = [];
        arrayToInsert[index] = question || null;
        currentAnnotations.push({
          annotationLanguage: { identifier: dataLanguage?.split('-')[0] },
          annotationPropertyType: { identifier: 'cp:wizardStepQuestion' },
          value: JSON.stringify(arrayToInsert),
        });
      }

      const newFormState = _.set(
        cloneDeepWithMetadata((props.registry.formRef!.state as { formData: object }).formData),
        'annotations',
        currentAnnotations
      );

      props.registry.formRef?.setState({
        formData: newFormState,
      });
      props.registry.formRef?.onChange(
        newFormState,
        (
          props.registry.formRef.state as {
            errorSchema: ErrorSchema;
          }
        ).errorSchema || {}
      );
    },
    [props.registry.formRef]
  );

  const handleGroupRemoved = useCallback(
    (formData: object, index: number) => {
      const currentAnnotations = cloneDeepWithMetadata(
        props.registry.formRef!.state as {
          formData: { annotations: { annotationLanguage: object; annotationPropertyType: { identifier: string }; value: string }[] };
        }
      ).formData.annotations;
      const updatedAnnotations = currentAnnotations.map((annotation) => {
        if (annotation.annotationPropertyType.identifier === 'cp:wizardStepQuestion') {
          const currentValue = JSON.parse(annotation.value) as (string | null)[];
          const updatedValue = currentValue.filter((annotation, valueIndex) => {
            return valueIndex !== index;
          });
          annotation.value = JSON.stringify(updatedValue);
        }
        return annotation;
      });
      _.set(formData, 'annotations', updatedAnnotations);
    },
    [props.registry.formRef]
  );

  const activeAssignmentsCount = (props.formData as Schemas.CpType['assignments'])!.filter((assignment) => !assignment.deactivated).length;

  return (
    <>
      <div className={classNames(styles.titleWrapper, { [styles.multiline]: isExpanded })}>
        <div className={styles.labelWrapper}>
          <ExpandButton isExpanded={isExpanded} onClick={toggleExpanded} />
          <span className={styles.clickable} onClick={toggleExpanded}>
            <ArrayFieldTitle
              key={`array-field-title-${props.idSchema.$id}`}
              idSchema={props.idSchema}
              registry={props.registry}
              schema={props.schema as IJSONSchema}
              title={`${props.uiSchema['ui:title'] || props.title} (${activeAssignmentsCount || 0})`}
              required={props.required}
            />
          </span>
        </div>

        {(props.uiSchema['ui:description'] || props.schema.description) && (
          <ArrayFieldDescription
            key={`array-field-description-${props.idSchema.$id}`}
            idSchema={props.idSchema}
            description={props.uiSchema['ui:description'] || props.schema.description}
            detailed={isExpanded}
            hasDetails
          />
        )}
      </div>

      {isExpanded && (
        <div style={{ marginLeft: 32, minWidth: 'calc(100% - 32px)', width: 'fit-content' }}>
          <TreeView
            registry={props.registry}
            onChange={handleTreeChange}
            assignments={props.formData}
            formSchema={props.schema as IJSONSchema}
            onStepQuestionChange={handleStepQuestionChange}
            handleGroupRemoved={handleGroupRemoved}
          />
        </div>
      )}
    </>
  );
};

export default ArrayFieldTemplate;
